import {Channel, Member} from "../../slices/reduxTypes";
import {useDispatch, useSelector} from "react-redux";
import {usePrevious} from "./comments/chatItems/utils/usePrevious";
import {PdfMarkerProps} from "../../../shared/thread";
import {RootState} from "../../store";
import {useCallback, useEffect, useMemo, useState} from "react";
import {PdfChannelContainer} from "./pdfChannelContainer";
import {log} from "../../../utils/log";
import {useThreads} from "../../hooks/useThreads";
import {selectAllChatThreads} from "../../slices/chatThreadSlice";
import {setSelectedIssueId, setWindowState} from "./pdfChannelAppSlice";
import {popupModes} from "../common/popup";
import {Column} from "../common/motion_mui";
import {ThreadFormPopup} from "./threadFormPopup";
import {ThreadsPopup} from "./threadsPopup";
import {discunaAppFrontendApiHost} from "../../../discunaAppToolkit/discunaAppFrontendCom/discunaAppFrontendApiHost";
import {
    discunaCoreFrontendApiClient
} from "../../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {Alert, AlertTitle} from "@mui/material";
import {LICENSE_ID_MODERATOR} from "../../appEnv";
import {getLicenseId} from "../../../discunaAppToolkit/utils/licenseUtils";
import {useUpdateSW} from "../../hooks/useUpdateSW";

function sendMessage(data: { message: string, data: any }) {
    window.postMessage(data, "*")
}

export function PdfChannelApp({member, communityId, channel}: {
    member: Member,
    communityId: string,
    channel: Channel,
}) {
    // fetch threads
    const threadsAreLoading = useThreads(member.id, communityId, channel.id)
    const threads = useSelector(selectAllChatThreads)

    // callbacks
    const getMediaFolderUrl = useCallback(() => {
        return `communities/${communityId}/channels/${channel.id}`
    }, [channel.id, communityId])

    // states
    const [newThreadLink, setNewThreadLink] = useState<null | { link: PdfMarkerProps } | "invalid">(null)
    const [threadFormPopupMode, setThreadFormPopupMode] = useState<popupModes>("closed")
    const threadsPopupMode = useSelector((state: RootState) => state.pdfChannelApp.windowState)
    const prevThreadsPopupMode = usePrevious(threadsPopupMode)

    const dispatch = useDispatch()

    // update popup modes based on highlight
    useEffect(() => {
        if (newThreadLink && newThreadLink !== "invalid") {
            if (threadsPopupMode !== "closed") dispatch(setWindowState("closed"))
            if (threadFormPopupMode !== "expanded") setThreadFormPopupMode("expanded")
        } else if (newThreadLink === "invalid") {
            if (threadsPopupMode !== "closed") dispatch(setWindowState("closed"))
            if (threadFormPopupMode === "closed") setThreadFormPopupMode("minimized")
        } else {
            if (threadFormPopupMode !== "closed") setThreadFormPopupMode("closed")
            if (prevThreadsPopupMode === "closed") dispatch(setWindowState("minimized"))
        }
    }, [prevThreadsPopupMode, dispatch, newThreadLink, threadsPopupMode, threadFormPopupMode])

    // listen to messages from the PDF Viewer
    useEffect(() => {
        const evHandler = (event: MessageEvent) => {
            if (event.data.message) log(["message event", event.data])
            switch (event.data.message) {
                case "addPDFIssue":
                    setNewThreadLink(event.data.data as { link: PdfMarkerProps });
                    break
                case "setIssueId":

                    if (threadsPopupMode !== "minimized") dispatch(setWindowState("minimized"))
                    if (threadFormPopupMode !== "closed") setThreadFormPopupMode("closed")
                    // navigate("./issues/" + event.data.data)
                    dispatch(setSelectedIssueId(event.data.data))
                    break
                case "issueClicked":
                    dispatch(setWindowState("expanded"))
                    break
                case "selectionChange":
                    // setHighlight(event.data.data as { link: TextSelectionProps, text: string });
                    break
                case "invalidSelection":
                    setNewThreadLink("invalid");
                    break
                case "noSelection":
                    setNewThreadLink(null);
                    break
            }
        }
        window.addEventListener("message", evHandler, false)
        return () => {
            window.removeEventListener("message", evHandler);
        }
    }, [dispatch, threadFormPopupMode, threadsPopupMode])

    // tell discuna channel is ready
    useEffect(() => {
        if (!threadsAreLoading) {
            discunaCoreFrontendApiClient.pageIsReady()
        }
    }, [threadsAreLoading])

    // handle inbox message clicks
    const [queuedHighlightThreadId, setQueuedHighlightThreadId] = useState<null | string>(null)
    const docLoaded = useSelector((state: RootState) => state.pdfChannelApp.docLoaded)
    useEffect(() => {
        if (docLoaded && queuedHighlightThreadId) {
            sendMessage({
                message: "scrollIssueIntoView",
                data: queuedHighlightThreadId
            })
            setQueuedHighlightThreadId(null)
        }
    }, [docLoaded, queuedHighlightThreadId])
    const [highlight, setHighlight] = useState<null | string>(null)
    useEffect(() => {
        return discunaAppFrontendApiHost.onNotificationClick<{ threadId: string, commentId?: string }>((args) => {
            if (threads.map(t => t.id).includes(args.data.threadId)) {
                setHighlight(args.data.commentId ?? args.data.threadId)
                dispatch(setWindowState("expanded"))
                dispatch(setSelectedIssueId(args.data.threadId))
                setQueuedHighlightThreadId(args.data.threadId)
            }
        })
    }, [channel.id, dispatch, threads])


    // const memberLicenseId = useMemo(() => {
    //     if (!member) return null
    //     return getLicenseId(member.details.licenses ?? [])
    // }, [member])

    // const hasModeratorPrivileges = useMemo(() => {
    //     if (!member) return false
    //     return ["administrator", "moderator"].some(role => member.details.roles.includes(role))
    // }, [member])
    // const showLicenseWarningInit = useMemo(() => {
    //     return hasModeratorPrivileges && memberLicenseId !== LICENSE_ID_MODERATOR
    // }, [hasModeratorPrivileges, memberLicenseId])
    const showLicenseWarningInit = false
    const [showLicenseWarning, setShowLicenseWarning] = useState(showLicenseWarningInit)

    // check for updates
    const updateSWSnackbar = useUpdateSW()

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
            width: "100%",
            height: "100%"
        }}>
            {
                showLicenseWarning &&
                <Alert
                    sx={{
                        position: "fixed",
                        top: 16,
                        zIndex: 2,
                        mx: 2
                    }}
                    severity={"warning"}
                    onClose={() => setShowLicenseWarning(false)}
                >
                    <AlertTitle>Missing License</AlertTitle>
                    You have moderator privileges but you are using a free license. To use moderator privileges please
                    ask the community administrator to upgrade your license.
                </Alert>
            }

            <PdfChannelContainer channel={channel} communityId={communityId}/>
            <ThreadsPopup
                mode={threadsPopupMode}
                channel={channel}
                communityId={communityId}
                sendMessage={sendMessage}
                getMediaFolderUrl={getMediaFolderUrl}
                highlight={highlight}
                setHighlight={setHighlight}
            />
            <ThreadFormPopup
                mode={threadFormPopupMode}
                highlight={newThreadLink}
                setHighlight={setNewThreadLink}
                channelId={channel.id}
                communityId={communityId}
                getMediaFolderUrl={getMediaFolderUrl}
            />
            {updateSWSnackbar}
        </Column>

    )
}
