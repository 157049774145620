import React, {useEffect, useMemo, useRef, useState} from "react";
import useDimensions from "react-cool-dimensions";
import {isMobile as deviceIsMobile} from "react-device-detect";
import {BackgroundShadow, Column, ColumnMotion, MotionBox, PaperMotion} from "./motion_mui";
import debounce from "lodash.debounce";
import {SxProps} from "@mui/material";



export type popupModes = "closed" | "expanded" | "minimized"

export function Popup({mode, onCancel, children, windowHeightWhenMinimized, sx}: React.PropsWithChildren<{
    mode: popupModes,
    onCancel: () => void,
    windowHeightWhenMinimized: number,
    sx?: SxProps
}>) {
    const containerDims = useDimensions()
    const popupDims = useDimensions()

    // popup props
    const popupIsFullscreen = useMemo(() => containerDims.width < 500 || deviceIsMobile, [containerDims.width]);
    const popupWidth = useMemo(() => {
        if (popupIsFullscreen) return containerDims.width + "px"
        if (containerDims.width < 550) return 450 + "px"
        if (containerDims.width < 650) return 500 + "px"
        if (containerDims.width < 850) return 600 + "px"
        return 800 + "px"
    }, [containerDims.width, popupIsFullscreen]);

    const py = useMemo(() => popupIsFullscreen ? 0 : 2, [popupIsFullscreen])
    const popupY = useMemo(() => {
        if (mode === "expanded") return (popupIsFullscreen ? -containerDims.height :
            Math.max(-containerDims.height, -(containerDims.height + popupDims.height + 2 * py * 8) / 2)) + "px"
        else if (mode === "minimized") return -windowHeightWhenMinimized - py * 8 + "px"
        else return 0 + "px"
    }, [mode, popupIsFullscreen, containerDims.height, popupDims.height, py, windowHeightWhenMinimized]);

    // scroll container into to top when minimized
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (scrollContainerRef.current && mode !== "expanded") {
            scrollContainerRef.current.scrollTo({top: 0, behavior: "smooth"})
        }
    }, [mode, scrollContainerRef])

    // Fix scroll issue
    // When popup mode changes from minimized to expanded,
    // overflow has to be set to "auto" after animation has finished first
    const [overflowHidden, setOverflowHidden] = useState(true)
    const setOverflowAutoDebounced = useMemo(() => debounce(() => {
        setOverflowHidden(false)
    }, 300), [])
    useEffect(() => {
        if (mode === "expanded") setOverflowAutoDebounced()
        else setOverflowHidden(true)
    }, [mode, setOverflowAutoDebounced])

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"center"}
            ref={containerDims.observe}
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                minHeight: "fit-content",
                height: "100%",

                // do not block pointer events to elements below if not expanded
                // (popup is still visible and receives pointer events when minimized
                visibility: mode === "expanded" ? "visible" : "hidden",
                ...sx
            }}
        >
            {
                mode === "expanded" &&
                <BackgroundShadow onTap={onCancel}/>
            }
            <Column
                ref={scrollContainerRef}
                mainAxisAlignment={"start"}
                crossAxisAlignment={"center"}
                className={"qanda-hideScrollbar"} // avoid odd animation of popup
                id={"popupScrollContainer"}
                sx={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    // overflow: "auto",
                    overflow: overflowHidden ? "hidden" : "auto",
                    scrollbarWidth: 0,
                }}
                onClick={(e) => {
                    if ((e.target as HTMLDivElement).id === "popupScrollContainer") onCancel()
                }}
            >
                <MotionBox
                    ref={(ref) => popupDims.observe(ref as HTMLDivElement)}
                    sx={{
                        py,
                        top: "100%",
                        position: "relative",
                        ...(popupIsFullscreen) && {
                            minHeight: "100%",
                        }
                    }}
                    animate={{y: popupY}}
                >
                    <PaperMotion
                        style={{
                            position: "relative",
                            width: popupWidth,
                            // alignSelf: "center",
                            visibility: "visible",
                            height: "min-content",
                            // marginTop: popupMarginY,
                            // marginBottom: popupMarginY,
                            ...(popupIsFullscreen) && {
                                minHeight: "100%",
                                borderRadius: "0px"
                            }
                        }}
                    >
                        {
                            children
                        }
                    </PaperMotion>
                </MotionBox>
            </Column>
        </Column>
    )
}
