import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useMemo} from "react";
import {setSelectedPdfIssueMarker} from "../pdfViewerSlice";
import {MarkerPosition, PdfMarkerProps} from "../../../../../shared/thread";
import {RootState} from "../../../../store";

const normalizeRotation = (rotation: number): number =>
    rotation >= 0 ? rotation : 360 + rotation;

const getMarkerCssProperties = (
    position: MarkerPosition,
    rotation: number,
    scale: number,
    accepted: boolean,
    isSelected: boolean
): React.CSSProperties => {
    const r = normalizeRotation(rotation);
    const isSelectedBorderRadius = 2 * scale
    const isSelectedPadding = 1.2 * scale
    const backgroundColor = accepted ?
        "rgba(35, 155, 86, 0.7)" :
        "rgba(176, 58, 46, 0.7)"
    const d = 10 * scale + 2 * isSelectedPadding
    switch (r) {
        default:
            return {
                position: 'absolute',
                top: `calc(${position.top}% - ${5 * scale +
                (isSelected ? (isSelectedBorderRadius + isSelectedPadding) : 0)}px)`,
                left: `calc(${position.left}% - ${5 * scale +
                (isSelected ? (isSelectedBorderRadius + isSelectedPadding) : 0)}px)`,
                minHeight: `${d}px`,
                maxHeight: `${d}px`,
                minWidth: `${d}px`,
                maxWidth: `${d}px`,
                borderRadius: "50%",
                backgroundColor,
                boxSizing: "content-box",
                ...(isSelected) && {
                    backgroundClip: "content-box",
                    padding: isSelectedPadding,
                    border: `${isSelectedBorderRadius}px solid ${backgroundColor}`
                    // boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
                },
                zIndex: 3, // text layer has zIndex 3
            };
    }
}

export function PdfPageIssueMarkers({pageIndex, rotation, scale}: {
    pageIndex: number,
    rotation: number,
    scale: number
}) {
    const selectedPdfIssueMarker = useSelector((state: RootState) => state.pdfViewer.selectedPdfIssueMarker)
    const issueTeasers = useSelector((state: RootState) => state.pdfViewer.issueTeasers)

    const pageIssueTeasers = useMemo(() => {
        return issueTeasers.filter((issueTeaser) => issueTeaser.link.pageIndex === pageIndex)
    }, [issueTeasers, pageIndex])

    const dispatch = useDispatch()
    return (
        <>
            {
                pageIssueTeasers.map((issueTeaser) => {
                    const markerProps = issueTeaser.link as PdfMarkerProps
                    const markerStyle = getMarkerCssProperties(
                        markerProps.position,
                        pageIndex,
                        scale,
                        issueTeaser.solved??true,
                        issueTeaser.id === selectedPdfIssueMarker,
                    )
                    return (
                        <div
                            id={issueTeaser.id}
                            onMouseOver={(event) => {
                                window.postMessage({
                                    message: "setIssueId",
                                    data: issueTeaser.id
                                }, "*")
                                dispatch(setSelectedPdfIssueMarker(issueTeaser.id))
                            }}
                            onClick={(event) => {
                                window.postMessage({
                                    message: "issueClicked"
                                }, "*")

                            }}
                            key={issueTeaser.id}
                            className="qanda-pdf-marker" style={{
                            ...markerStyle,
                            // zIndex: 1000000000,
                        }}/>
                    )
                })
            }
        </>
    )
}
