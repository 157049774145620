import {Outlet} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {cancelablePromise} from "../../../utils/cancelablePromise";
import {
    discunaCoreFrontendApiClient
} from "../../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {getAuth} from "firebase/auth";

export function WithAuth(props: { protectedComponent?: JSX.Element, audience: string }) {

    // check that userId == memberIdHint, o/w redirect and get new token
    const [memberIdHint, setMemberIdHint] = useState<string | null>(null)
    useEffect(() => {
        const [task, cancel] = cancelablePromise(discunaCoreFrontendApiClient.getMemberId())
        task.then(setMemberIdHint).catch((e) => {
            console.error("aborted get member id", e)
        })
        return cancel
    }, [])

    // firebase auth
    const auth = useSelector((state: RootState) => state.auth)

    // oidc auth
    const oauth = useAuth();

    console.log("memberIdHint", memberIdHint)

    useEffect(() => {
        if(!memberIdHint) return
        if(auth.hasLoaded && auth.isSignedIn && auth.uid !== memberIdHint) {
            console.log("signing out")
            getAuth().signOut()
        }
    }, [auth, memberIdHint])

    useEffect(() => {
        // if(!memberIdHint) return
        if (auth.hasLoaded && !auth.isSignedIn && !oauth.isLoading) {
            console.log("signinRedirect")
            // save current url
            window.localStorage.setItem("auth/targetURL", window.location.href)

            // redirect
            // this updates oauth state => needs to be called inside useEffect
            oauth.signinRedirect({
                extraQueryParams: {
                    audience: props.audience
                }
            })
        }
    }, [auth, memberIdHint, oauth, props.audience])

    if (auth.hasLoaded && auth.isSignedIn && memberIdHint === auth.uid) {
        return <>
            {/*<Button onClick={() => getAuth().signOut()}>Sign out</Button>*/}
            {props.protectedComponent ?? <Outlet/>}
        </>
    }

    if (oauth.error) {
        return <div>Oops... {oauth.error.message}</div>;
    }

    return <>Authenticate</>
}
