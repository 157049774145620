import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {filterAcceptedOptions, filterCategoryOptions, filterPageRangeOptions} from "./threadOverviewPage/filterComponents";
import {ThreadOverviewPage} from "./threadOverviewPage/threadOverviewPage";
import {RootState} from "../../store";
import {WINDOW_HEIGHT_WHEN_MINIMIZED} from "../../constants";
import {setWindowState} from "./pdfChannelAppSlice";
import {Popup, popupModes} from "../common/popup";
import {Channel} from "../../slices/reduxTypes";
import {ThreadPage} from "./threadPage";


export function ThreadsPopup({channel, communityId, sendMessage, mode, getMediaFolderUrl, highlight, setHighlight}: {
    channel: Channel,
    communityId: string,
    sendMessage: (data: { message: string, data: any }) => void,
    mode: popupModes,
    getMediaFolderUrl: () => string,
    highlight: string | null,
    setHighlight: (value: string | null) => void,
}) {
    // states
    const [filterCategory, setFilterCategory] = useState<filterCategoryOptions>("")
    const [filterAccepted, setFilterAccepted] = useState<filterAcceptedOptions>("")
    const [filterUnread, setFilterUnread] = useState(false)
    const [filterPageRange, setFilterPageRange] = useState<filterPageRangeOptions>([0, channel.details.numPages - 1])

    const selectedIssueId = useSelector((state: RootState) => state.pdfChannelApp.selectedIssueId)

    const dispatch = useDispatch()

    return (
        <Popup windowHeightWhenMinimized={WINDOW_HEIGHT_WHEN_MINIMIZED} mode={mode} onCancel={() => {
            dispatch(setWindowState("minimized"))
        }}>
            {
                selectedIssueId ?
                    <ThreadPage
                        threadId={selectedIssueId}
                        channelId={channel.id}
                        communityId={communityId}
                        getMediaFolderUrl={getMediaFolderUrl}
                        highlight={highlight}
                        setHighlight={setHighlight}
                    /> :
                    <ThreadOverviewPage
                        channelId={channel.id}
                        communityId={communityId}
                        sendMessage={sendMessage}
                        // numPages provided for page range filter
                        numPages={channel.details.numPages}
                        filterCategory={filterCategory}
                        setFilterCategory={setFilterCategory}
                        filterAccepted={filterAccepted}
                        setFilterAccepted={setFilterAccepted}
                        filterUnread={filterUnread}
                        setFilterUnread={setFilterUnread}
                        filterPageRange={filterPageRange}
                        setFilterPageRange={setFilterPageRange}
                    />
            }
        </Popup>
    )
}
