import React, {useEffect, useState} from "react";
import {LinearProgress} from "@mui/material";


// Test
// https://arxiv.org/pdf/1606.03476.pdf
// https://moodle-app2.let.ethz.ch/pluginfile.php/610737/mod_resource/content/1/cv_lecture01_intro_and_pinhole_model-2x2.pdf
// https://people.math.ethz.ch/~grsam/NUMPDEFL/NUMPDE.pdf
// https://www.sam.math.ethz.ch/~grsam/NUMPDEFL/NUMPDE.pdf
export function DownloadPdfBrowser({url, onComplete, onError}: {
    url: string,
    onComplete: (data: Uint8Array) => void,
    onError: (msg: string) => void,
}) {

    const [receivedBytes, setReceivedBytes] = useState<null | number>(null)
    const [totalSize, setTotalSize] = useState<null | number>(null)
    const progress = (receivedBytes && totalSize) ? (receivedBytes / totalSize * 100) : 0

    useEffect(() => {
        fetchPdf(
            url,
            (totalSize) => setTotalSize(totalSize),
            (receivedBytes) => setReceivedBytes(receivedBytes),
            onComplete,
            (msg) => onError(msg)
        ).catch((e) => {
            onError(e.message)
        })
    }, [onComplete, onError, url])

    return (
        <LinearProgress variant="determinate" value={progress} />
    )
}


export async function fetchPdf(
    url: string,
    onStart: (totalBytes: number) => void,
    onProgress: (receivedBytes: number) => void,
    onComplete: (data: Uint8Array) => void,
    onError: (error: string) => void,
) {
    // https://javascript.info/fetch-progress
    // Step 1: start the fetch and obtain a reader
    const response = await fetch(url)
    // response.arrayBuffer()
    const type = response.headers.get("content-type")
    if(type !== "application/pdf") return onError("content type is not application/pdf")
    const responseBody = response.body
    if(!responseBody) return onError("response has no body")
    const reader = responseBody.getReader();

    // Step 2: get total length
    const contentLengthStr = response.headers.get('Content-Length')
    if(!contentLengthStr) return onError("content length not in header")
    const contentLength = parseInt(contentLengthStr)
    onStart(contentLength)

    // Step 3: read the data
    let receivedLength = 0; // received that many bytes at the moment
    const chunks = []; // array of received binary chunks (comprises the body)
    while(true) {
        const {value} = await reader.read();

        if (!value) {
            break;
        }

        chunks.push(value);
        receivedLength += value.length;
        onProgress(receivedLength)
    }

    // Step 4: concatenate chunks into single Uint8Array
    let chunksAll = new Uint8Array(receivedLength); // (4.1)
    let position = 0;
    for(let chunk of chunks) {
        chunksAll.set(chunk, position); // (4.2)
        position += chunk.length;
    }
    onComplete(chunksAll)
}
