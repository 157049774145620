import {useSelector} from "react-redux";
import {RootState} from "../store";
import {selectMemberById} from "../slices/memberSlice";
import {useChannel} from "../hooks/useChannel";
import {useParams} from "react-router-dom";
import {useMember} from "../hooks/useMember";
import {LoadingScreen} from "../components/common/loadingScreen";
import {useEffect} from "react";
import {getDoc} from "@firebase/firestore";
import {firestoreDb} from "../../firebase/firebase";
import {doc} from "firebase/firestore";
import {PdfChannelApp} from "../components/channel/pdfChannelApp";
import {useMemberSecrets} from "../hooks/useMemberSecrets";

// NOTE this component is general and can be used for different kinds of channels
export function PdfChannel() {
    const {communityId, channelId} = useParams()
    if(!communityId || !channelId) throw Error("url malformed")

    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("unauthenticated")

    // fetch member
    const memberIsLoading = useMember({memberId: auth.uid, communityId})
    const member = useSelector((state: RootState) => selectMemberById(state, auth.uid))

    // fetch member secrets
    const memberSecretsIsLoading = useMemberSecrets({memberId: auth.uid, communityId})

    // fetch channel
    const channelIsLoading = useChannel({communityId, channelId})
    const channel = useSelector((state: RootState) => state.channel.channel)

    useEffect(() => {
        console.log(communityId, channelId, auth, member, "channel", channel)
        //
        const channelRef = doc(firestoreDb, "communities", communityId, "channels", channelId)
        getDoc(channelRef).then((doc) => {
            console.log("channel data", doc.exists(), doc.data())
        }).catch((e) => {
            console.error("error channel data", e)
        })

    }, [auth, channel, channelId, communityId, member])



    if (channelIsLoading || memberIsLoading || !member || !channel) return <LoadingScreen/>
    return <PdfChannelApp member={member} channel={channel} communityId={communityId}/>
}
