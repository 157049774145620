import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import {Alert, AlertTitle, Snackbar, Typography} from "@mui/material";
import {getPdfInfo, loadPDfFromData} from "../pdf/utils";
import {RootState, store} from "../../store";
import {DownloadCloudPdf} from "../pdf/loader/cloud/downloadCloudPdf";
import {mediaBufferManager} from "../../mediaBufferManager";
import {Column} from "../common/motion_mui";
import {PdfViewerDesktopOnly} from "../pdf/viewer/pdfViewerDesktopOnly";
import {UploadPdf} from "../pdf/loader/fs/uploadPdf";
import {DownloadPdfBrowser} from "../pdf/loader/url/donwloadPdfBrowser";
import {MarkdownRenderer} from "../markdownEditor/markdownRenderer";
import {Channel} from "../../slices/reduxTypes";
import {getCloudPdfUrl} from "../../../shared";


export function PdfChannelContainer({channel, communityId}: {
    channel: Channel,
    communityId: string
}) {

    const fileId = channel.details.fileId as string
    const pdfFetchInfo = channel.details.pdfFetchInfo
    const {id: channelId} = channel
    const buffId = `${communityId}/${channelId}`

    // pdf data
    // const [pdfData, setPDFData] = useState<null | Uint8Array>(null)
    const [arrayBuffer, setArrayBuffer] = useState<null | Uint8Array>(null)
    const pdfData = useMemo(() => {
        // console.log("updating pdfData", arrayBuffer)
        if(!arrayBuffer) return null
        // return new Uint8Array(arrayBuffer.slice(0))
        return new Uint8Array(arrayBuffer)
    }, [arrayBuffer])
    const [pdfNotLoaded, setPdfNotLoaded] = useState(false)


    // get filepath from offline data
    // NOTE only needed when pdfFetchInfo.type === "fs"
    // const channelOfflineData = useSelector((state: RootState) => selectChannelOfflineDataById(state, channel.id))
    // const filepath = channelOfflineData?.filePath

    // --------------------------
    // PDF Load Errors
    // --------------------------

    // URL & FS
    const [pdfVersionError, setPdfVersionError] = useState<boolean>(false)
    const [unknownError, setUnknownError] = useState<string | null>(null)

    // URL
    const [networkError, setNetworkError] = useState<null | string>(null)

    // FS
    const [pdfDoesNotExistError, setPdfDoesNotExistError] = useState<boolean>(false)
    const [pdfInvalidError, setPdfInvalidError] = useState<boolean>(false)

    const hasErrors = pdfVersionError || unknownError || networkError || pdfDoesNotExistError || pdfInvalidError


    // --------------------------
    // Load PDF
    // --------------------------

    // check if file is in Buffer
    // TODO do this in parent component
    useEffect(() => {
        // TODO media buffer manager
        const data = mediaBufferManager.getBuffer(buffId)
        // if (data) return setPDFData(data)
        if (data) {
            setPdfNotLoaded(false)
            // NOTE this seems to be important
            setArrayBuffer(new Uint8Array(data.buffer.slice(0)))
            // setArrayBuffer(new Uint8Array(data.buffer))
        } else {
            setPdfNotLoaded(true)
        }
    }, [buffId])

    const completeHandler = useCallback((buffer: Uint8Array, filePathResult?: string) => {
        setArrayBuffer(buffer.slice(0, buffer.byteLength))
        const data = new Uint8Array(buffer.slice(0))
        mediaBufferManager.addBuffer(buffId, data)
        getPdfInfo(data)
            .then((pdfInfo) => {
                if (pdfInfo.fingerprint !== fileId) return setPdfVersionError(true)
                setPdfNotLoaded(false)
            })
            .catch((e) => {
                console.error("error in complete handler", e)
                setUnknownError(e.message)
            })
    }, [buffId, fileId])

    const [error, setError] = useState<null | string>(null)


    // render
    return (
        <>
            {
                (!pdfData || pdfNotLoaded || hasErrors) &&
                // (!pdfData || hasErrors) &&

                <Column mainAxisAlignment={"center"} crossAxisAlignment={"stretch"} sx={{
                    margin: "auto",
                    width: "100%",
                    height: "100%",
                    p: 2,
                    overflow: "auto",
                    boxSizing: "border-box",
                    maxWidth: "600px",
                    bgcolor: "#E5E7E9"
                }}>
                    {
                        // (pdfNotLoaded || !pdfData) && !hasErrors &&
                        pdfNotLoaded && !pdfData && !hasErrors &&
                        <>
                            {
                                // TODO show description
                                pdfFetchInfo.type === "fs" &&
                                <>
                                    <MarkdownRenderer markdown={pdfFetchInfo.description ?? ""}
                                                      textAlign={"center"}/>
                                    {/*<Typography variant={"body1"} sx={{textAlign: "center"}}>{pdfFetchInfo.description}</Typography>*/}
                                    <UploadPdf onComplete={(pdf) => completeHandler(pdf.data, pdf.path)}
                                               onError={setError}/>
                                </>
                            }
                            {
                                pdfFetchInfo.type === "url" &&
                                <DownloadPdfBrowser url={pdfFetchInfo.url} onComplete={completeHandler}
                                                    onError={(msg) => setNetworkError(msg)}/>
                            }
                            {
                                pdfFetchInfo.type === "cloud" &&
                                <DownloadCloudPdf
                                    path={getCloudPdfUrl({
                                        communityId: communityId,
                                        channelId: channel.id,
                                    })}
                                    onComplete={completeHandler}
                                    onError={(msg) => setNetworkError(msg)}
                                />
                            }

                        </>
                    }
                    {
                        networkError &&
                        <Alert severity={"error"}>
                            {
                                <AlertTitle>Network Error</AlertTitle>
                            }
                            {
                                "To access the PDF inside the browser, the corresponding HTTP response header must include " +
                                "{ \"Access-Control-Allow-Origin\": \"https://pdf.discuna.com\" }."
                            }
                        </Alert>
                    }
                    {
                        pdfVersionError && pdfFetchInfo.type === "url" &&
                        <Alert severity={"error"}>
                            {
                                `PDF versions do not match. The PDF at ${pdfFetchInfo.url} has changed.`
                            }
                        </Alert>
                    }
                    {
                        pdfVersionError && pdfFetchInfo.type === "fs" &&
                        <Alert severity={"error"} onClose={() => {
                            setPdfVersionError(false)
                            setPdfNotLoaded(true)
                            setArrayBuffer(null)
                        }}>
                            {
                                `PDF versions do not match. Please update the filepath.`
                            }
                        </Alert>
                    }
                    {
                        (pdfDoesNotExistError || pdfInvalidError) && pdfFetchInfo.type === "fs" &&
                        <Alert severity={"error"} onClose={() => {
                            setPdfDoesNotExistError(false)
                            setPdfInvalidError(false)
                            setPdfNotLoaded(true)
                        }}>
                            {
                                `PDF could not be loaded.`
                            }
                        </Alert>
                    }
                    {
                        unknownError &&
                        <Alert severity={"error"} onClose={() => {
                            setArrayBuffer(null)
                            setPdfNotLoaded(true)
                            setUnknownError(null)
                        }}>
                            {
                                `Unknown Error occurred.`
                            }
                        </Alert>
                    }
                </Column>
            }
            {
                arrayBuffer && !pdfNotLoaded &&
                <PdfViewerDesktopOnly
                    channelId={channel.id}
                    channelName={channel.name}
                    buffer={arrayBuffer}
                />
            }
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={error != null}
                      autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    )
}
