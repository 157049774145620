import {useEffect} from "react";
import {useDispatch} from "react-redux";
import useMemoizedState from "./useMemoizedState";
import {removeOneThread, upsertOneThread} from "../slices/chatThreadSlice";
import {ThreadFirestoreData} from "../../shared/thread";
import {firestoreManager} from "../../firebase/queryManager";
import {getFirestoreThreadsColRef} from "../../firebase/firestoreReferences";

export function useThreads(userId: string, communityId: string, channelId: string) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useMemoizedState(true)

    // fetch threads
    useEffect(() => {
        const threadsColRef = getFirestoreThreadsColRef({communityId, channelId})
        const {isNew, cancel} = firestoreManager.queryCollectionSnapshots(
            threadsColRef,
            (querySnapshot) => {
                setIsLoading(false)
                querySnapshot.docChanges().forEach((change) => {
                    const doc = change.doc
                    if (change.type === "removed") {
                        dispatch(removeOneThread(doc.id))
                    } else {
                        const threadData = doc.data() as ThreadFirestoreData
                        const solved = (threadData.solved === undefined || threadData.solved)
                        dispatch(upsertOneThread({id: doc.id, data: {...threadData, solved}}))
                    }
                })
            }
        )
        setIsLoading(isNew)
        return cancel
    }, [dispatch, channelId, communityId, setIsLoading])
    return isLoading
}
