import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PdfMarkersPlugin} from "./markers/PdfMarkersPlugin";
import {Fab, IconButton} from "@mui/material";
import {
    RenderCurrentScaleProps,
    RenderZoomInProps,
    RenderZoomOutProps,
    RenderZoomProps,
    zoomPlugin
} from "@react-pdf-viewer/zoom";
import {DestinationOffsetFromViewport, PdfJs, SpecialZoomLevel, Viewer} from "@react-pdf-viewer/core";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import ZoomOutOutlinedIcon from "@mui/icons-material/ZoomOutOutlined";
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import {Bookmarks, jumpToDestinationType} from "./bookmarks/bookmarks";
import FindInPageIcon from '@mui/icons-material/FindInPage';
import BookIcon from '@mui/icons-material/Book';
import {searchPlugin} from '@react-pdf-viewer/search';
import {pageNavigationPlugin} from '@react-pdf-viewer/page-navigation';
// import {getFilePlugin} from '@react-pdf-viewer/get-file';


import {PdfSearch} from "./search/pdfSearch";
import {SidebarContainer} from "./sidebarContainer";
import "./pdfViewerStyles.css"
import '@react-pdf-viewer/search/lib/styles/index.css';
import {useDispatch, useSelector} from "react-redux";
import {setCurrPageIndex, setPdfIssueTeasers, setSelectedPdfIssueMarker} from "./pdfViewerSlice";
import {PageNavigation} from "./pageNavigation";
import {PageRenderer} from "./pageRenderer";
import {AddPdfMarker} from "./markers/addPdfMarker";
import {Icon} from '@iconify/react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {RootState} from "../../../store";
import {selectAllChatThreads} from "../../../slices/chatThreadSlice";
import {PdfThreadTeaser} from "../../../../shared/pdfThreadTeaser";
import {PdfMarkerProps} from "../../../../shared";
import {Column, Row} from "../../common/motion_mui";
import {setDocLoaded} from "../../channel/pdfChannelAppSlice";


function CustomCurrentScale(props: RenderCurrentScaleProps & {
    setScale: (scale: number) => void
}) {

    useEffect(() => {
        const scaleTo = 0.21739130434 * props.scale
        props.setScale(scaleTo)
    }, [props, props.scale])
    return (
        <div style={{
            visibility: "hidden",
            position: "absolute"
        }}/>
    );
}

// let pdfData: Uint8Array | null = null

export function PdfViewerDesktopOnly({buffer, channelId, channelName, global, showGlobalContextMenu, setShowGlobalContextMenu}: {
    buffer: ArrayBuffer,
    channelId: string,
    channelName: string,
    global?: boolean,
    showGlobalContextMenu?: boolean,
    setShowGlobalContextMenu?: (value: boolean) => void,
}) {
    // const pdfData = useRef<Uint8Array>(new Uint8Array(buffer))
    // const pdfData = useMemo(() => {
    //     // console.log("updating pdfData 2", buffer)
    //     return new Uint8Array(buffer.slice(0))
    // }, [buffer])
    // useEffect(() => {
    //     if(buffer.byteLength > 0) {
    //         pdfData = new Uint8Array(buffer)
    //     }
    // }, [buffer])

    // console.log("pdfData", pdfData)

    const pdfData = useMemo(() => new Uint8Array(buffer), [buffer])
    // const dataUrl = useMemo(() => URL.createObjectURL(new Blob([pdfData])), [pdfData])
    // const bufferRef = useRef(buffer)
    // console.log(buffer)

    // NOTE issues are fetched in docChannelApp
    const threads = useSelector((state: RootState) => selectAllChatThreads(state))
    const pdfThreadTeasers = useMemo(() => {
        const pdfThreadTeasers = [] as PdfThreadTeaser[]
        threads.forEach((thread) => {
            pdfThreadTeasers.push({
                id: thread.id,
                title: thread.details.title,
                link: thread.details.link as PdfMarkerProps,
                // filter
                solved: thread.solved,
                category: thread.category
            })
        })
        return pdfThreadTeasers
    }, [threads])


    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setPdfIssueTeasers(pdfThreadTeasers))
    }, [dispatch, pdfThreadTeasers])

    const issueTeasersMap = useMemo(() => {
        const pdfIssueTeasers = new Map<string, PdfThreadTeaser>()
        pdfThreadTeasers.forEach((issueTeaser) => {
            pdfIssueTeasers.set(issueTeaser.id, issueTeaser)
        })
        return pdfIssueTeasers
    }, [pdfThreadTeasers])

    const [jumpToDestination, setJumpToDestination] = useState<null | jumpToDestinationType>(null)

    const messageHandler = useCallback(async (event: MessageEvent) => {
        // if (event.data.message) console.log("PDF viewer event - message received: ", event.data.message)
        if (event.data.message === "scrollIssueIntoView") {
            const issueId = event.data.data as string
            dispatch(setSelectedPdfIssueMarker(issueId))
            const pdfIssueTeaser = issueTeasersMap.get(issueId)

            if (pdfIssueTeaser && jumpToDestination) {
                const {pageIndex, position} = pdfIssueTeaser.link
                try {
                    await jumpToDestination(
                        pageIndex,
                        (viewportWidth, viewportHeight) => 16 + viewportHeight * (100 - position.top) / 100,
                        (viewportWidth) => viewportWidth * position.left / 100 - 16,
                    )
                    console.log("jumped to destination")
                } catch (e) {
                    console.error(e)
                }

            }
        }
        if (event.data.message === "setHighlightedIssueId") {
            const issueId = event.data.data
            dispatch(setSelectedPdfIssueMarker(issueId))
        }
    }, [dispatch, issueTeasersMap, jumpToDestination])

    // listen to messages from popup
    useEffect(() => {
        // // send message to popup so to load issue
        // window.parent.postMessage({
        //     message: "qandaIframeReady"
        // }, "*")
        // try communicating with the Chrome extension
        window.addEventListener("message", messageHandler, false)
        return () => {
            console.log("PDF viewer event - window listener removed")
            window.removeEventListener("message", messageHandler);
        }
    }, [dispatch, messageHandler])

    // zoom
    const pdfMarkersPlugin = PdfMarkersPlugin()
    const zoomPluginInstance = zoomPlugin()
    const {Zoom, CurrentScale, ZoomIn, ZoomOut} = zoomPluginInstance;
    const [scale, setScale] = useState(1)


    // got to page
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const {jumpToPage} = pageNavigationPluginInstance

    // search PDF
    const [showSearch, setShowSearch] = useState(false)
    const searchPluginInstance = searchPlugin()

    // bookmarks (contents)
    const [doc, setDoc] = useState<null | PdfJs.PdfDocument>(null)
    // console.log("doc", doc)
    const [outline, setOutline] = useState<null | PdfJs.Outline[]>(null)
    const [showContents, setShowContents] = useState(false)
    // const theme = useTheme()

    // const saveFileToDownloads = useCallback(() => {
    //     if(pdfData) {
    //         console.log("saveFileToDownloads")
    //         const element = document.createElement('a');
    //         element.setAttribute('href', 'data:application/pdf;' + URL.createObjectURL(
    //             new Blob([pdfData], { type: 'application/df' } /* (1) */)
    //         ))
    //         element.setAttribute('download', `${channel.details.name}.pdf`);
    //         element.style.display = 'none';
    //         document.body.appendChild(element);
    //
    //         element.click();
    //
    //         document.body.removeChild(element);
    //     }
    //
    // }, [pdfData, channel.details.name])

    const saveFile = useCallback(async () => {
        if (pdfData) {

            // create a new handle
            const newHandle = await window.showSaveFilePicker({
                suggestedName: `${channelName}`,
                types: [{
                    accept: {
                        "application/pdf": [".pdf"]
                    }
                }]
            });

            // create a FileSystemWritableFileStream to write to
            const writableStream = await newHandle.createWritable();

            // write our file
            await writableStream.write(pdfData);

            // close the file and write the contents to disk.
            await writableStream.close();
        }
    }, [pdfData, channelName])

    // const fileNameGenerator = useCallback(() => `${channel.details.name}.pdf`, [channel.details.name])

    // const getFilePluginInstance = getFilePlugin({
    //     // fileNameGenerator
    // });
    // const {Download} = getFilePluginInstance;
    //
    // const [downloadInitiated, setDownloadInitiated] = useState(false)



    return (
        <div style={{
            maxWidth: "100%",
            maxHeight: "100%",
            minWidth: "100%",
            minHeight: "100%",
            position: "relative",
            // overflow: "auto",
            // minHeight: "100vh",
            // maxWidth: "100vw",
            // maxHeight: "100vh",
            backgroundColor: "#E5E7E9",
            // ...(isInAddMode) && {
            //     cursor: "none",
            // },
        }}>
            <SidebarContainer
                sidebarItem={
                    <div
                        style={{
                            overflowX: "auto",
                            maxWidth: "100%",
                            flexGrow: 1,
                        }}
                    >
                        <Bookmarks doc={doc} jumpToDestination={jumpToDestination} outline={outline}/>
                    </div>
                }
                visible={showContents}
                title={"Contents"}
                onClose={() => {
                    setShowContents(false)
                    setShowSearch(false)
                }}/>
            <SidebarContainer
                sidebarItem={<PdfSearch searchPluginInstance={searchPluginInstance}/>}
                visible={showSearch}
                title={"Search"}
                onClose={() => {
                    setShowContents(false)
                    setShowSearch(false)
                }}/>


            <Row mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                maxWidth: "100%",
                maxHeight: "100%",
                minWidth: "100%",
                minHeight: "100%",
                position: "relative",
            }}>
                <div style={{
                    // position: "relative",
                    minHeight: "100vh",
                    maxHeight: "100vh",
                    width: "calc(100% - 56px)",
                    maxWidth: "calc(100% - 56px)",
                    overflow: "auto",
                    boxSizing: "border-box",
                }}>
                    {
                        pdfData &&
                        <Viewer
                            // enableSmoothScroll={false}
                            key={"pdf-viewer"}
                            fileUrl={pdfData}
                            defaultScale={SpecialZoomLevel.PageWidth}
                            plugins={[
                                pdfMarkersPlugin,
                                zoomPluginInstance,
                                searchPluginInstance,
                                pageNavigationPluginInstance,
                                // getFilePluginInstance,
                                // bookmarks
                                {
                                    install(pluginFunctions) {
                                        console.log("jumpToDestination", pluginFunctions.jumpToDestination)
                                        setJumpToDestination(() => (pageIndex: number,
                                                                    bottomOffset: number | DestinationOffsetFromViewport,
                                                                    leftOffset: number | DestinationOffsetFromViewport,
                                                                    scaleTo?: number | SpecialZoomLevel) => {
                                            console.log("jump", pageIndex, bottomOffset, leftOffset, scaleTo)
                                            return pluginFunctions.jumpToDestination(pageIndex, bottomOffset, leftOffset, scaleTo)
                                        })
                                    },
                                    onDocumentLoad(props) {
                                        setDoc(props.doc)
                                        dispatch(setDocLoaded(true))
                                        props.doc.getOutline().then((outline) => {
                                            setOutline(outline)
                                        })
                                    },
                                    onViewerStateChange: (viewerState) => {
                                        dispatch(setCurrPageIndex(viewerState.pageIndex))
                                        return viewerState;
                                    },
                                }
                                // propertiesPluginInstance
                            ]}
                            renderPage={(props) =>
                                <PageRenderer
                                    // search={searchPluginInstance}
                                    renderPageProps={props}
                                />}
                        />
                    }
                </div>
                <Column
                    sx={{
                        backgroundColor: "#E5E7E9",
                        // margin: "1px"
                        // '&:hover': {
                        //     backgroundColor: "secondary.main",
                        // },
                        transition: "all 200ms ease-in-out",
                        width: "56px",
                        padding: "8px 0px",
                        height: "min-content"
                    }}
                    // className={composeClasses([classes.pdfToolbar, classes.smoothTransitionAll200])}
                    mainAxisAlignment={"start"}
                    crossAxisAlignment={"center"}
                >
                    <ZoomIn>
                        {(props: RenderZoomInProps) => (
                            <IconButton onClick={props.onClick}>
                                <ZoomInOutlinedIcon/>
                            </IconButton>
                        )}
                    </ZoomIn>
                    <ZoomOut>
                        {(props: RenderZoomOutProps) => (
                            <IconButton onClick={props.onClick}>
                                <ZoomOutOutlinedIcon/>
                            </IconButton>
                        )}
                    </ZoomOut>
                    <Zoom>
                        {(props: RenderZoomProps) => (
                            <IconButton onClick={() => props.onZoom(SpecialZoomLevel.PageWidth)}>
                                <Icon icon="fluent:auto-fit-width-24-regular"/>

                                {/*<ZoomOutMapOutlinedIcon/>*/}
                            </IconButton>
                        )}
                    </Zoom>
                    <Zoom>
                        {(props: RenderZoomProps) => (
                            <IconButton onClick={() => props.onZoom(SpecialZoomLevel.PageFit)}>
                                <Icon icon="fluent:auto-fit-height-24-regular"/>

                                {/*<ZoomOutMapOutlinedIcon/>*/}
                            </IconButton>
                        )}
                    </Zoom>
                    <CurrentScale>
                        {(props: RenderCurrentScaleProps) => CustomCurrentScale({...props, setScale})}
                    </CurrentScale>
                    <IconButton onClick={() => {
                        setShowSearch(false)
                        setShowContents(!showContents)
                    }}>
                        {
                            showContents ?
                                <BookIcon/> :
                                <BookOutlinedIcon/>
                        }
                    </IconButton>
                    <IconButton onClick={() => {
                        setShowContents(false)
                        setShowSearch(!showSearch)
                    }}>
                        {
                            showSearch ?
                                <FindInPageIcon/> :
                                <FindInPageOutlinedIcon/>
                        }
                    </IconButton>
                    {/*<Download>*/}
                    {/*    {(props) => (*/}
                    {/*        <IconButton onClick={() => {*/}
                    {/*            if (window.discunaDesktopAPI || window.qandaDesktopAPI) {*/}
                    {/*                // props.onClick()*/}
                    {/*                saveFileToDownloads()*/}
                    {/*                setDownloadInitiated(true)*/}
                    {/*            } else {*/}
                    {/*                saveFile().catch(e => console.error(e))*/}
                    {/*            }*/}
                    {/*        }}>*/}
                    {/*            <FileDownloadOutlinedIcon/>*/}
                    {/*        </IconButton>*/}
                    {/*    )}*/}
                    {/*</Download>*/}
                    {/*{*/}
                    {/*    <IconButton onClick={() => {*/}
                    {/*        saveFile().catch(e => console.error(e))*/}
                    {/*    }}>*/}
                    {/*        <FileDownloadOutlinedIcon/>*/}
                    {/*    </IconButton>*/}
                    {/*}*/}
                    {
                        doc !== null &&
                        <PageNavigation numPages={doc.numPages} jumpToPage={jumpToPage}/>
                    }
                    {
                        global &&
                        <Fab
                            color="primary"
                            aria-label="add"
                            onClick={() => {
                                if(setShowGlobalContextMenu)
                                    setShowGlobalContextMenu(!showGlobalContextMenu)
                            }}
                            sx={{
                                mt: 2,
                                minHeight: "46px",
                                maxHeight: "46px",
                                minWidth: "46px",
                                maxWidth: "46px",
                            }}
                        >
                            <AddOutlinedIcon/>
                        </Fab>
                    }
                </Column>
                {
                    !global &&
                    <AddPdfMarker scale={scale}/>
                }
            </Row>
            {/*{*/}
            {/*    <Snackbar*/}
            {/*        anchorOrigin={{*/}
            {/*            vertical: 'top',*/}
            {/*            horizontal: 'center'*/}
            {/*        }}*/}
            {/*        open={downloadInitiated}*/}
            {/*        onClose={() => setDownloadInitiated(false)}*/}
            {/*        autoHideDuration={6000}*/}
            {/*    >*/}
            {/*        <Alert severity={"info"} onClose={() => setDownloadInitiated(false)} sx={{width: '100%'}}>*/}
            {/*            PDF saved to Downloads folder*/}
            {/*        </Alert>*/}
            {/*    </Snackbar>*/}
            {/*}*/}
        </div>
    );
}
