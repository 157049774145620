import {PopupHeader} from "../common/popupHeader";
import {Box, IconButton} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {selectChatThreadById} from "../../slices/chatThreadSlice";
import {WINDOW_HEIGHT_WHEN_MINIMIZED} from "../../constants";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import {setSelectedIssueId, setWindowState} from "./pdfChannelAppSlice";
import {ExpandLessOutlined} from "@mui/icons-material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {ChatThread} from "./chatThread";

export function ThreadPage({threadId, channelId, communityId, getMediaFolderUrl, highlight, setHighlight}: {
    threadId: string,
    channelId: string,
    communityId: string,
    getMediaFolderUrl: () => string,
    highlight: string | null,
    setHighlight: (value: string | null) => void,
}) {

    const thread = useSelector((state: RootState) => selectChatThreadById(state, threadId))
    const windowState = useSelector((state: RootState) => state.pdfChannelApp.windowState)

    const dispatch = useDispatch()


    if (!thread) return null

    return (
        <>
            <PopupHeader
                windowHeightWhenMinimized={WINDOW_HEIGHT_WHEN_MINIMIZED}
                windowState={windowState}
                title={thread.details.title}
                leftButton={
                    <IconButton edge={"start"}
                                onClick={
                                    (_) => {
                                        // navigate("../..")
                                        dispatch(setSelectedIssueId(null))
                                        dispatch(setWindowState("expanded"))
                                    }}>
                        <MenuOutlinedIcon/>
                    </IconButton>

                }
                rightButton={
                    windowState === "minimized" ?
                        <IconButton edge={"end"}
                                    onClick={() => {
                                        dispatch(setWindowState("closed"))
                                    }}>
                            <CloseOutlinedIcon/>
                        </IconButton> :
                        <IconButton edge={"end"}
                                    onClick={() => dispatch(setWindowState("minimized"))}>
                            <ExpandLessOutlined style={{transform: "rotate(180deg)"}}/>
                        </IconButton>
                }
                titleClickCallback={() => {
                    dispatch(setWindowState("expanded"))
                }}
            />
            {
                windowState === "expanded" ?
                    <ChatThread
                        communityId={communityId}
                        channelId={channelId}
                        threadId={threadId}
                        getMediaFolderUrl={getMediaFolderUrl}
                        highlight={highlight}
                        setHighlight={setHighlight}
                    /> :
                    <Box sx={{minHeight: "100px"}}/>
            }
        </>
    )
}
