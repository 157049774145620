import {IconButton, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import React from "react";

export function PdfFileInfoItem({name, onDelete}: {
    name: string,
    onDelete: () => void,
}) {
    return (
        <List disablePadding={true} sx={{
            my: 1,
            borderRadius: 1,
            bgcolor: "#D5F5E3"
            // backgroundColor: status === "loading" ? "#F0F3F4" : status === "success" ? "#D5F5E3" : "#FADBD8",
        }}>
            <ListItem
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={onDelete}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                }
            >
                <ListItemIcon>
                    <CheckOutlinedIcon/>
                </ListItemIcon>
                <ListItemText primary={name}/>
            </ListItem>
        </List>
    )
}
