import {
    createEntityAdapter,
    createSlice, PayloadAction
} from "@reduxjs/toolkit";
import {arraySaveDelete, arraySavePush} from "../../shared/utils/arrayOps";
import {
    createComment,
    createThread,
    deleteComment,
    toggleCommentReaction, toggleThreadSubscription,
    updateComment, updateThreadTitle
} from "../discunaAppApi";
import {Thread} from "./reduxTypes";
import {RootState} from "../store";
import {createCommentFromArgs} from "../../shared";
import {firebaseAuth} from "../../firebase/firebase";
import {ThreadFirestoreData, createThreadFromArgs} from "../../shared/thread";


const chatThreadsAdapter = createEntityAdapter<Thread>()

const chatThreadSlice = createSlice({
    name: "chatThreads",
    initialState: chatThreadsAdapter.getInitialState({}),
    reducers: {
        setAllComments: chatThreadsAdapter.setAll,
        addOneThread: chatThreadsAdapter.addOne,
        removeOneThread: chatThreadsAdapter.removeOne,
        updateOneThread: chatThreadsAdapter.updateOne,
        removeALlThreads: chatThreadsAdapter.removeAll,
        upsertOneThread: (state, action: PayloadAction<{ id: string, data: ThreadFirestoreData }>) => {
            const thread = state.entities[action.payload.id]
            const userId = firebaseAuth.currentUser?.uid ?? ""
            if (!thread) {
                chatThreadsAdapter.addOne(state, {
                    id: action.payload.id,
                    isSynced: true,
                    ...action.payload.data
                })
            } else {
                // NOTE this is a bit of a hack to prevent arriving data from overwriting local changes
                const newThread = {
                    id: action.payload.id,
                    isSynced: true,
                    ...action.payload.data
                }
                const prevComments = thread.details.comments
                const nextComments = action.payload.data.details.comments
                const commentsMaybeUpdated = Object.keys(nextComments).filter(id => prevComments[id] && nextComments[id])
                for (const id of commentsMaybeUpdated) {
                    newThread.details.comments[id] = nextComments[id]
                    if (prevComments[id].body !== nextComments[id].body && prevComments[id].authorId === userId) {
                        newThread.details.comments[id].body = prevComments[id].body
                    }
                    for (const emoji of Object.keys(prevComments[id].reactions)) {
                        if (prevComments[id].reactions[emoji].includes(userId)) {
                            newThread.details.comments[id].reactions[emoji] = arraySavePush(nextComments[id].reactions[emoji], userId)
                        } else {
                            newThread.details.comments[id].reactions[emoji] = arraySaveDelete(nextComments[id].reactions[emoji], userId)
                        }
                    }
                }
                if (thread.details.subscribers.includes(userId)) {
                    newThread.details.subscribers = arraySavePush(newThread.details.subscribers, userId)
                } else {
                    newThread.details.subscribers = arraySaveDelete(newThread.details.subscribers, userId)
                }
                newThread.details.title = thread.details.title
                chatThreadsAdapter.upsertOne(state, newThread)
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(createThread.fulfilled, (state, action) => {
                const thread = state.entities[action.meta.arg.threadId]
                if(thread) thread.isSynced = true
            })
            .addCase(createThread.pending, (state, action) => {
                const args = action.meta.arg
                const member = action.meta.member
                if (!member) throw Error("member is undefined")
                // chatThreadsAdapter.addOne(state, {
                //     id: args.threadId,
                //     isSynced: false,
                //     ...createThreadFromArgs({
                //         id: member.id,
                //         name: member.details.name
                //     }, args, action.meta.requiresAction)
                // })
            })
            .addCase(toggleThreadSubscription.fulfilled, (state, action) => {
                const thread = state.entities[action.meta.arg.threadId]
                if(thread) thread.isSynced = true
            })
            .addCase(toggleThreadSubscription.pending, (state, action) => {
                const args = action.meta.arg
                const member = action.meta.member
                if (!member) throw Error("member is undefined")
                const thread = state.entities[args.threadId]
                if (!thread) return
                thread.details.subscribers = args.isSubscribed ?
                    arraySavePush(thread.details.subscribers, member.id) :
                    arraySaveDelete(thread.details.subscribers, member.id)
            })
            .addCase(updateThreadTitle.fulfilled, (state, action) => {
                const thread = state.entities[action.meta.arg.threadId]
                if(thread) thread.isSynced = true
            })
            .addCase(updateThreadTitle.pending, (state, action) => {
                const args = action.meta.arg
                const member = action.meta.member
                if (!member) throw Error("member is undefined")
                const thread = state.entities[args.threadId]
                if (!thread) return
                thread.details.title = args.title
            })
            .addCase(createComment.fulfilled, (state, action) => {
                const thread = state.entities[action.meta.arg.threadId]
                if (thread) {
                    thread.isSynced = true
                }
            })
            .addCase(createComment.pending, (state, action) => {
                const args = action.meta.arg
                const member = action.meta.member
                if (!member) throw Error("member is undefined")
                const thread = state.entities[args.threadId]
                if (!thread) return
                thread.isSynced = false
                thread.details.comments[args.commentId] = createCommentFromArgs({
                    id: member.id,
                    name: member.details.name
                }, args)
                thread.details.subscribers = arraySavePush(thread.details.subscribers, member.id)
            })
            .addCase(createComment.rejected, (state, action) => {
                // TODO
                console.log(action.error.message)
            })
            .addCase(toggleCommentReaction.pending, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (!thread) return
                const comment = thread.details.comments[args.commentId]
                if (!firebaseAuth.currentUser) return
                const userId = firebaseAuth.currentUser.uid
                thread.details.comments[args.commentId].reactions[args.reaction] = args.value ?
                    arraySavePush(comment.reactions[args.reaction] ?? [], userId) :
                    arraySaveDelete(comment.reactions[args.reaction] ?? [], userId)
            })
            .addCase(toggleCommentReaction.fulfilled, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (thread) thread.isSynced = true
            })
            .addCase(deleteComment.pending, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (!thread) return
                thread.isSynced = false

                // check if comment has replies
                const commentList = Object.keys(thread.details.comments).map(id => ({id, ...thread.details.comments[id]}))
                // if comment has no replies, delete entire thread or comment. Otherwise, delete body of comment.
                // NOTE cascaded delete is not implemented. However, it is not necessary because the server will delete all replies.
                if (commentList.filter(c => c.replyTo === args.commentId).length === 0) {
                    if (args.commentId === args.threadId) {
                        chatThreadsAdapter.removeOne(state, args.threadId)
                    } else {
                        delete thread.details.comments[args.commentId]
                    }
                } else {
                    thread.details.comments[args.commentId].body = null
                }
            })
            .addCase(deleteComment.fulfilled, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (thread) thread.isSynced = true
            })
            .addCase(updateComment.pending, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (!thread) return
                const comment = thread.details.comments[args.commentId]
                if (comment) comment.body = args.body
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                const args = action.meta.arg
                const thread = state.entities[args.threadId]
                if (thread) thread.isSynced = true
            })
    }
})

export const chatThreadsReducer = chatThreadSlice.reducer


export const {
    upsertOneThread,
    removeOneThread
} = chatThreadSlice.actions

// selectors
export const {
    selectById: selectChatThreadById,
    selectAll: selectAllChatThreads,
} = chatThreadsAdapter.getSelectors((state: RootState) => state.chatThreads)
