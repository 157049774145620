import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    Alert,
    Snackbar,
    TextField
} from "@mui/material";
import {deleteObject, getStorage, ref, getMetadata} from "firebase/storage";
import {getTmpPdfUrl, PdfFetchInfo} from "../../../../shared";
import {UploadPdf} from "../loader/fs/uploadPdf";
import {UploadPdfToCloud} from "../loader/cloud/uploadPdfToCloud";
import {log} from "../../../../utils/log";
import {PdfData} from "./addPDFChannel";

export function PdfCloudForm({channelName, setChannelName, pdfData, setPdfData, setPdfFetchInfo}: {
    channelName: string,
    setChannelName: (value: string) => void,
    pdfData: PdfData | null,
    setPdfData: (value: PdfData | null) => void,
    setPdfFetchInfo: (value: PdfFetchInfo | null) => void,
}) {
    // states
    const [error, setError] = useState<null | string>(null)
    const fileIdRef = useRef<null | string>(null)

    const resetStates = useCallback(() => {
        setPdfData(null)
        setChannelName("")
    }, [setChannelName, setPdfData])

    const successCallback = useCallback((fileId: string) => {
        // setMaybePdfChannelId(channelId)
        const cloudPdfUrl = getTmpPdfUrl(fileId)
        const storage = getStorage();
        const pdfRef = ref(storage, cloudPdfUrl);
        getMetadata(pdfRef).then(meta => console.log(meta))
        fileIdRef.current = fileId
        setPdfFetchInfo({
            type: "cloud",
        })
    }, [setPdfFetchInfo])

    const deletePdf = useCallback((fileId: string) => {
        log("delete PDF...")
        resetStates()
        const cloudPdfUrl = getTmpPdfUrl(fileId)
        const storage = getStorage();
        const pdfRef = ref(storage, cloudPdfUrl);
        fileIdRef.current = null
        deleteObject(pdfRef).then(() => {
            log("PDF deletion success")
        }).catch((error) => {
            log("PDF deletion error", error.message)
        });

    }, [resetStates])

    // Clean up uploaded PDF file if component is destroyed
    useEffect(() => {
        return () => {
            // if fileIdRef.current is null, the file was already deleted
            if(fileIdRef.current) {
                log("use Effect", fileIdRef.current)
                deletePdf(fileIdRef.current)
            }
        }
    }, [deletePdf])


    return (
        <>
            {
                !pdfData ?
                    <UploadPdf
                        onError={setError}
                        onComplete={(pdf) => {
                            // NOTE pdf.size is in bytes
                            if (pdf.size > 100 * 1024 * 1024) return setError("We currently only allow files not larger than 100MB")
                            setChannelName(pdf.name)
                            setPdfData(pdf)
                        }}
                    /> :
                    <UploadPdfToCloud
                        key={pdfData.fingerprint}
                        pdf={pdfData}
                        onSuccess={successCallback}
                        onDelete={deletePdf}
                        fileId={pdfData.fingerprint}
                    />
            }
            <TextField
                sx={{mt: 2}}
                variant={"filled"}
                value={channelName}
                onChange={(e) => {
                    // TODO validate channel name
                    setChannelName(e.target.value)
                }}
                label={"Channel name"}
            />
            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={error != null}
                      autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    )
}
