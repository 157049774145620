import {MessageClient} from "./messageClient";


export class DiscunaCoreFrontendApiClient {
    messageClient: MessageClient

    constructor() {
        this.messageClient = new MessageClient({
            meta: {
                api: "discuna-core-frontend-api",
                version: "0.0.1"
            }
        })
        this.messageClient.start()
    }

    // --------------------------
    // getters
    // --------------------------

    async getChannelName() {
        return await this.messageClient.get("getChannelName")
    }

    async getNotificationData<T=any>() {
        const response = await this.messageClient.get("getNotificationData")
        return JSON.parse(response) as T
    }

    async getMemberId() {
        return await this.messageClient.get("getMemberId")
    }

    // --------------------------
    // setters
    // --------------------------

    async setDisableCreateChannelButton(disable: boolean) {
        return await this.messageClient.post({cmd: "setDisableCreateChannelButton", payload: {disable}})
    }

    // --------------------------
    // events
    // --------------------------

    async pageIsReady() {
        return await this.messageClient.post({cmd: "pageIsReady"}).catch(e => console.error(e))
    }
}

export const discunaCoreFrontendApiClient = new DiscunaCoreFrontendApiClient()
