/**
 * @file-overview
 * This file contains the environment variables for the application.
 */

export const API_TOKEN_ENDPOINT = process.env.REACT_APP_API_TOKEN_ENDPOINT as string
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string
export const AUDIENCE = process.env.REACT_APP_AUDIENCE as string
export const AUTHORITY = process.env.REACT_APP_AUTHORITY as string
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI as string
export const POST_LOGOUT_REDIRECT_URI = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI as string
export const DISCUNA_ORIGIN = process.env.REACT_APP_DISCUNA_ORIGIN as string
export const LICENSE_ID_MODERATOR=process.env.REACT_APP_LICENSE_ID_MODERATOR as string
