import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slider, Typography} from "@mui/material";
import React from "react";
import {Column} from "../../common/motion_mui";
import {Category} from "../../../../shared/thread";

export type filterCategoryOptions = Category | ""
export type filterAcceptedOptions = "" | "accepted" | "notAccepted"
export type filterPageRangeOptions = [number, number]

export function FilterCategory(props: {
    value: filterCategoryOptions,
    onChange: (value: filterCategoryOptions) => void
}) {
    return (
        <FormControl variant="outlined" style={{
            marginTop: 16,
            minWidth: 150
        }}>
            <InputLabel id="issue-category-input">Category</InputLabel>
            <Select
                labelId="select-issue-category-label"
                id="select-issue-category"
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value as filterCategoryOptions)
                }}
                label="Category"
            >
                <MenuItem value={""}><i>None</i></MenuItem>
                <MenuItem value={"question"}>Question</MenuItem>
                <MenuItem value={"error"}>Error</MenuItem>
                <MenuItem value={"opinion"}>Opinion</MenuItem>
                <MenuItem value={"suggestion"}>Suggestion</MenuItem>
                <MenuItem value={"info"}>Info</MenuItem>
            </Select>
        </FormControl>
    )
}

export function FilterAccepted(props: {
    value: filterAcceptedOptions,
    onChange: (value: filterAcceptedOptions) => void
}) {
    return (
        <FormControl variant="outlined" style={{
            marginTop: 16,
            minWidth: 150
        }}>
            <InputLabel id="accepted-input">Answer</InputLabel>
            <Select
                labelId="select-accepted-label"
                id="select-accepted"
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value as filterAcceptedOptions)
                }}
                label="Accepted"
            >
                <MenuItem value={""}><i>None</i></MenuItem>
                <MenuItem value={"accepted"}>Accepted</MenuItem>
                <MenuItem value={"notAccepted"}>Not Accepted</MenuItem>
            </Select>
        </FormControl>
    )
}

export function FilterUnread(props: {
    value: boolean,
    onChange: (value: boolean) => void
}) {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={props.value}
                    onChange={() => {
                        props.onChange(!props.value)
                    }}
                    name="only unread"/>
            }
            label="only unread"
        />
    )
}

export function FilterPageRange(props: {
    value: [number, number],
    onChange: (value: [number, number]) => void,
    numPages: number
}) {
    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"start"} sx={{
            // marginTop: 1,
            // marginLeft: 6,
            // marginRight: 6,
            mx: 1
        }}>
            <Typography gutterBottom sx={{
                ml: -1
            }}>
                Page range
            </Typography>
            <Slider
                // sx={{
                //     px: 2,
                //     boxSizing: "border-box"
                // }}
                value={props.value}

                min={0}
                max={props.numPages - 1}
                onChange={(e, newValue) => {
                    props.onChange(newValue as [number, number])
                }}
                valueLabelDisplay="auto"
                aria-labelledby="page-range-slider"
                getAriaValueText={(value) => "Page range"}
            />
        </Column>
    )
}
