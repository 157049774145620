import {RenderPageProps} from "@react-pdf-viewer/core";
import {pdfViewerEventManager} from "./pdfViewerEventManager";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import {RootState} from "../../../store";

export function PageRenderer({renderPageProps}: {
    renderPageProps: RenderPageProps,
}) {
    const isInAddMode = useSelector((state: RootState) => state.pdfViewer.inAddMode)

    useEffect(() => {
        if (renderPageProps.textLayerRendered) {
            renderPageProps.markRendered(renderPageProps.pageIndex);
        }
    }, [renderPageProps.textLayerRendered]);

    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
            }}
            onClick={(e) => {
                const target = e.currentTarget
                if (target instanceof HTMLElement) {
                    const pageRect = target.getBoundingClientRect()
                    const top = (e.clientY - pageRect.top) / pageRect.height * 100;
                    const left = (e.clientX - pageRect.left) / pageRect.width * 100;
                    pdfViewerEventManager._emit("mouseClickInPage", {
                        pagePosition: {
                            top,
                            left
                        },
                        pageIndex: renderPageProps.pageIndex
                    })
                }
            }}
            onMouseEnter={() => {
                pdfViewerEventManager._emit("mouseEntersPage", {
                    pageIndex: renderPageProps.pageIndex
                })
            }}
            onMouseLeave={() => {
                pdfViewerEventManager._emit("mouseLeavesPage", {
                    pageIndex: renderPageProps.pageIndex
                })
            }}
        >
            {
                isInAddMode &&
                <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    cursor: "none",
                    zIndex: 4,
                    left: "0%",
                    top: "0%"
                }}/>
            }
            {renderPageProps.canvasLayer.children}
            {renderPageProps.textLayer.children}
            {renderPageProps.annotationLayer.children}
        </div>
    )
}
