import * as React from 'react';
import {
    createStore,
    Plugin,
    PluginFunctions,
    PluginOnAnnotationLayerRender,
    PluginRenderPageLayer
} from '@react-pdf-viewer/core';
import {PdfPageIssueMarkers} from "./pdfPageIssueMarkers";

export default interface StoreProps {
    getPagesContainer?(): HTMLElement;
}

export function PdfMarkersPlugin(): Plugin {
    const store = React.useMemo(() => createStore<StoreProps>({}), []);

    const renderPageLayer = (renderPageProps: PluginRenderPageLayer) => {
        return <PdfPageIssueMarkers
            pageIndex={renderPageProps.pageIndex}
            scale={renderPageProps.scale}
            rotation={renderPageProps.rotation}
        />
    }

    const onAnnotationLayerRender = (pluginOnAnnotationLayerRender: PluginOnAnnotationLayerRender) => {
        const annotationEle = pluginOnAnnotationLayerRender.container
        const links = annotationEle.querySelectorAll("a")
        links.forEach((link) => {
            if (link.href.length > 0) link.setAttribute("target", "_blank")
        })
    }

    // const onTextLayerRender = (pluginOnTextLayerRender: PluginOnTextLayerRender) => {
    //     const textEle = pluginOnTextLayerRender.ele;
    //
    //
    //     const mouseClickHandler = (e: MouseEvent) => {
    //         const pageRect = textEle.getBoundingClientRect();
    //         const mouseTop = e.clientY - pageRect.top;
    //         const mouseLeft = e.clientX - pageRect.left;
    //         props.onCreateMarker({
    //             position: {
    //                 top: mouseTop / pageRect.height * 100,
    //                 left: mouseLeft / pageRect.width * 100,
    //             },
    //             pageIndex: pluginOnTextLayerRender.pageIndex
    //             // TODO what about rotation
    //         })
    //     };
    //
    //     const mouseMoveHandler = (e: MouseEvent) => {
    //         const pageRect = textEle.getBoundingClientRect();
    //         const mouseTop = e.clientY - pageRect.top;
    //         const mouseLeft = e.clientX - pageRect.left;
    //         console.log(mouseTop, mouseLeft)
    //     }
    //
    //     const mouseOverHandler = (e: MouseEvent) => {
    //         console.log('mouse over', pluginOnTextLayerRender.pageIndex)
    //         props.onMouseOver(pluginOnTextLayerRender.pageIndex)
    //     }
    //
    //     const mouseOutHandler = (e: MouseEvent) => {
    //         console.log('mouse out')
    //         props.onMouseOut()
    //     }
    //
    //     switch (pluginOnTextLayerRender.status) {
    //         case LayerRenderStatus.PreRender:
    //             textEle.removeEventListener('click', mouseClickHandler);
    //             // textEle.removeEventListener('mousemove', mouseMoveHandler)
    //             textEle.removeEventListener('mouseenter', mouseOverHandler)
    //             textEle.removeEventListener('mouseleave', mouseOutHandler)
    //             break;
    //         case LayerRenderStatus.DidRender:
    //             textEle.addEventListener('click', mouseClickHandler);
    //             // textEle.addEventListener('mousemove', mouseMoveHandler)
    //             textEle.addEventListener('mouseenter', mouseOverHandler)
    //             textEle.addEventListener('mouseleave', mouseOutHandler)
    //             break;
    //         default:
    //             break;
    //     }
    // };

    return {
        install: (pluginFunctions: PluginFunctions) => {
            store.update('getPagesContainer', pluginFunctions.getPagesContainer)
        },
        renderPageLayer,
        // onTextLayerRender,
        onAnnotationLayerRender
    }
}
