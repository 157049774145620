import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {popupModes} from "../common/popup";

export type editState = null | {
    refType: "answer" | "issue",
    refId: string,
}

export interface appState {
    windowState: popupModes,
    selectedIssueId: string | null,
    edit: editState,
    docLoaded: boolean,
    // pdfWindowState: "closed" | "expanded",
    // pdfUrl: string,
    // minimizedOffsetY: number,
    // width: number
}

const initialState = {
    windowState: "minimized",
    selectedIssueId: null,
    edit: null,
    docLoaded: false,
    // minimizedOffsetY: 0,
    // width: 0
} as appState

const pdfChannelAppSlice = createSlice({
    name: 'pdfChannelApp',
    initialState: initialState,
    reducers: {
        setWindowState(state, action: PayloadAction<popupModes>) {
            state.windowState = action.payload
        },
        setSelectedIssueId(state, action: PayloadAction<string | null>) {
            state.selectedIssueId = action.payload
        },
        setEdit(state, action: PayloadAction<editState>) {
            state.edit = action.payload
        },
        setDocLoaded(state, action: PayloadAction<boolean>) {
            state.docLoaded = action.payload
        }
        // setMinimizedOffsetY(state, action: PayloadAction<number>){
        //     state.minimizedOffsetY = action.payload
        // },
        // setPdfWindowState(state, action: PayloadAction<"closed" | "expanded">){
        //     state.pdfWindowState = action.payload
        // },
        // setPdfUrl(state, action: PayloadAction<string>){
        //     state.pdfUrl = action.payload
        // },
        // setPopupWidth(state, action: PayloadAction<number>){
        //     state.width = action.payload
        // },
    }
})

export const {setWindowState, setSelectedIssueId, setDocLoaded} = pdfChannelAppSlice.actions
export const pdfChannelAppReducer = pdfChannelAppSlice.reducer
