import {WINDOW_HEIGHT_WHEN_MINIMIZED} from "../../constants";
import {Column} from "../common/motion_mui";
import {PopupHeader} from "../common/popupHeader";
import {Box, IconButton} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {ThreadForm} from "./threadForm";
import {Popup, popupModes} from "../common/popup";
import {PdfMarkerProps} from "../../../shared/thread";


export function ThreadFormPopup({highlight, setHighlight, mode, channelId, communityId, getMediaFolderUrl}: {
    highlight: null | { link: PdfMarkerProps } | "invalid",
    setHighlight: (highlight: null | { link: PdfMarkerProps } | "invalid") => void,
    mode: popupModes,
    channelId: string,
    communityId: string,
    getMediaFolderUrl: () => string,
}) {
    return (
        <Popup
            windowHeightWhenMinimized={WINDOW_HEIGHT_WHEN_MINIMIZED}
            mode={mode}
            onCancel={() => {
                if (highlight && highlight !== "invalid") {
                    setHighlight(null)
                }
            }}
            // sx={{
            //     p: 2
            // }}
        >
            <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
                // px: 2,
                width: "100%"
            }}>
                <PopupHeader
                    windowState={mode}
                    windowHeightWhenMinimized={WINDOW_HEIGHT_WHEN_MINIMIZED}
                    title={
                        <Box sx={{
                            typography: "h6",
                            fontWeight: "bold",
                            padding: `0px 0px`,
                        }}>
                            Add Thread
                        </Box>
                    }
                    rightButton={
                        <IconButton edge={"end"} style={{color: "black"}}
                                    onClick={() => setHighlight(null)}>
                            <CloseOutlinedIcon/>
                        </IconButton>
                    }
                />
                {
                    highlight && highlight !== "invalid" &&
                    <ThreadForm
                        autoFocus={true}
                        sx={{
                            mx: "20px",
                            mb: "20px",
                            mt: "4px"
                        }}
                        channelId={channelId}
                        communityId={communityId}
                        getMediaFolderUrl={getMediaFolderUrl}
                        link={highlight.link}
                        onSend={() => setHighlight(null)}
                    />
                }
            </Column>
        </Popup>
    )
}
