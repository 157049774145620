import {getStorage, ref, uploadBytesResumable} from "firebase/storage";
import React, {useEffect, useState} from "react";
import {getAuth} from "firebase/auth";
import {CircularProgress, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import {getTmpPdfUrl} from "../../../../../shared";
import {PdfData} from "../../form/addPDFChannel";
import {log} from "../../../../../utils/log";

export function UploadPdfToCloud({pdf, fileId, onSuccess, onDelete}: {
    pdf: PdfData,
    fileId: string,
    onSuccess: (fileId: string) => void,
    onDelete: (fileId: string) => void,
}) {

    const [progress, setProgress] = useState<number | "success" | string>(0)

    // NOTE in StrictMode, useEffect is called twice
    useEffect(() => {
        const storagePath = getTmpPdfUrl(fileId)

        const storage = getStorage()
        const storageRef = ref(storage, storagePath)
        const auth = getAuth()
        if (!auth.currentUser) return setProgress("unauthenticated")
        const metadata = {
            contentType: "application/pdf",
            customMetadata: {
                publisher: auth.currentUser.uid,
            }
        }

        const uploadTask = uploadBytesResumable(storageRef, pdf.data, metadata)

        uploadTask.on("state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress)
                log("upload progress state", snapshot.state)
                // snapshot.state: "paused" | "running"
            },
            (error) => {
                // A full list of error codes is available at
                // error.code
                // https://firebase.google.com/docs/storage/web/handle-errors
                console.error("Error uploading PDF:", error)
                setProgress(error.code)
            },
            () => {
                // Upload completed successfully
                log("upload completed successfully")
                setProgress("success")
                onSuccess(fileId)
            }
        )

        return () => {
            if (uploadTask.snapshot.state !== "success" &&
                uploadTask.snapshot.state !== "error") {
                uploadTask.cancel()
            }
        }

    }, [fileId, onSuccess, pdf.data])


    return (
        <List disablePadding={true} sx={{
            my: 1,
            borderRadius: 1,
            bgcolor: typeof progress === "number" ? "#F0F3F4" :
                progress === "success" ? "#D5F5E3" : "#FADBD8",
            overflow: "hidden"
            // backgroundColor: status === "loading" ? "#F0F3F4" : status === "success" ? "#D5F5E3" : "#FADBD8",
        }}>
            {
                typeof progress === "number" &&
                <LinearProgress sx={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%"
                }} variant="determinate" value={progress}/>
            }
            <ListItem
                sx={{
                    position: "relative"
                }}
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={() => {
                        onDelete(fileId)
                    }}>
                        <DeleteOutlineOutlinedIcon/>
                    </IconButton>
                }
            >
                <ListItemIcon>
                    {
                        typeof progress === "number" ?
                            <CircularProgress size={24}/> :
                            progress === "success" ?
                                <CheckOutlinedIcon/> :
                                <ErrorOutlineOutlinedIcon/>
                    }
                </ListItemIcon>

                <ListItemText primary={
                    typeof progress === "number" ?
                        `Uploading ${pdf.name}` :
                        progress === "success" ?
                            `Uploaded ${pdf.name}` :
                            `Error occurred: ${progress}`
                }/>

            </ListItem>
        </List>
    )
}
