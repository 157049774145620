import { useEffect, useRef } from "react";

// https://usehooks.com/usePrevious/
// https://stackoverflow.com/a/57706747/7446235
export const usePrevious = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
