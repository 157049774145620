import React, {useState} from "react";
import {
    Alert,
    Button,
    Snackbar,
    TextField
} from "@mui/material";
import {useDispatch} from "react-redux";
import {UploadPdf} from "../loader/fs/uploadPdf";
import {MarkdownEditor} from "../../markdownEditor/markdownEditor";
import {generateId} from "../../../discunaAppApi";
import {PdfFileInfoItem} from "../loader/fs/pdfFileInfoItem";
import {store} from "../../../store";
import {mediaBufferManager} from "../../../mediaBufferManager";
import {PdfFetchInfo} from "../../../../shared";
import {PdfData} from "./addPDFChannel";

export function PdfFsForm({channelName, setChannelName, pdfData, setPdfData, setPdfFetchInfo}: {
    channelName: string,
    setChannelName: (value: string) => void,
    pdfData: PdfData | null,
    setPdfData: (value: PdfData | null) => void,
    setPdfFetchInfo: (value: PdfFetchInfo | null) => void,
}) {
    const [pdfDescription, setPDFDescription] = useState("")
    const [error, setError] = useState<null | string>(null)

    return (
        <>
            {
                !pdfData ?
                    <UploadPdf
                        onError={setError}
                        onComplete={(pdf) => {
                            setPdfData(pdf)
                            setChannelName(pdf.name)
                        }}
                    /> :
                    <PdfFileInfoItem
                        name={pdfData.name}
                        onDelete={() => {
                            setPdfData(null)
                            setChannelName("")
                        }}
                    />
            }
            <TextField value={channelName}
                       variant={"filled"}
                       label={"PDF title"}
                       sx={{
                           mt: 2
                       }}
                       onChange={(e) => {
                           setChannelName(e.currentTarget.value)
                       }}/>
            <MarkdownEditor placeholder={"PDF info (e.g. DOI, exact title + version, or purchase link)"} sx={{mt: 2}}
                            markdown={pdfDescription}
                            onChange={(value) => {
                                setPDFDescription(value)
                                setPdfFetchInfo({
                                    type: "fs",
                                    description: pdfDescription
                                })
                            }}/>

            <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}} open={error != null}
                      autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    )
}



