import {AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit'
import {authReducer} from "./slices/authSlice";
import {chatThreadsReducer} from "./slices/chatThreadSlice";
import {memberReducer} from "./slices/memberSlice";
import {channelReducer} from "./slices/channelSlice";
import {pdfViewerReducer} from "./components/pdf/viewer/pdfViewerSlice";
import {pdfChannelAppReducer} from "./components/channel/pdfChannelAppSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {memberSecretsReducer} from "./slices/memberSecretsSlice";



const SIGNOUT_REQUEST = "SIGNOUT_REQUEST"

export const signOutFromStore = () => ({
    type: SIGNOUT_REQUEST
})

const combinedReducer = combineReducers({

    // auth
    auth: authReducer,

    // channel (we only have one channel)
    channel: channelReducer,

    // members (we only have one community)
    members: memberReducer,

    // member secrets
    memberSecrets: memberSecretsReducer,

    // comments
    chatThreads: chatThreadsReducer,

    pdfViewer: pdfViewerReducer,

    pdfChannelApp: pdfChannelAppReducer,

})

// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const rootReducer = (state: any, action: AnyAction) => {
    if (action.type === SIGNOUT_REQUEST) {
        // for all keys defined in your persistConfig(s)
        // for redux persist
        // storage.removeItem('persist:root')
        // storage.removeItem('persist:otherKey')
        return combinedReducer(undefined, action);
    }
    return combinedReducer(state, action);
};



export const store = configureStore({
    reducer: rootReducer,
    // middleware: [
    //     save()
    // ],
    // preloadedState: load()
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>
// Inferred bodyType: {posts: PostsState, discussion: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// you can also create some redux hooks using the above explicit types
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
