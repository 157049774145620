import {CommentFirestoreData, chatReactionType} from "../../../../shared";
import {useCallback, useMemo, useState} from "react";
import {ChatCommentContainerMotion, CommentNode} from "./chatCommentContainer";
import {strokeStates} from "./chatItems/utils/stroke";
import {ChatPostData} from "./commentForm";
import {Column} from "../../common/motion_mui";
import {mergeParticipants} from "./commentUtils";

export function ChatThreadComments({threadId, comments, highlight, setHighlight, maxCommentLength, ...otherProps}: {
    userId: string,
    threadId: string,
    comments: Record<string, CommentFirestoreData>,

    highlight: string | null,
    setHighlight: (value: string | null) => void,

    onReply: (replyTo: string, data: ChatPostData) => void,
    onUpdateComment: (commentId: string, body: string) => void,
    onDeleteComment: (commentId: string) => void,
    getMediaFolderUrl: () => string,
    onToggleEmoji: (commentId: string, emoji: chatReactionType, value: boolean) => void,
    maxCommentLength?: number | undefined,
}) {

    const [collapsed, setCollapsed] = useState({} as Record<string, "all" | null | string>)
    const onCollapse = useCallback((commentId: string, collapsedValue: string | "all" | null) => {
        setCollapsed({
            ...collapsed,
            [commentId]: collapsedValue
        })
    }, [collapsed])

    // build tree from comments
    const commentTree = useMemo(() => {
        const commentList = Object.keys(comments).map(id => ({id, ...comments[id]}))

        function getCommentNode(id: string, threadId: null | string): CommentNode {
            const replies = commentList.filter(comment => comment.replyTo === id)
            // we sort replies (old first) so that comments maintain their thread IDs
            replies.sort((a, b) => a.createdOn - b.createdOn)
            const nodeThreadId = threadId ?? id
            const node = {
                id,
                parent: null,
                children: [],
                totalChildCount: replies.length,
                collapsed: collapsed[id] ?? null,
                subThreads: [nodeThreadId],
                participants: {},
                ...comments[id],
                latestMessage: null,
                forceCollapse: null
            } as CommentNode
            replies.forEach((reply, i) => {
                const replyNode = getCommentNode(reply.id, i === 0 ? nodeThreadId : null)
                replyNode.parent = node
                node.children.push(replyNode)
                node.totalChildCount += replyNode.totalChildCount
                node.subThreads.push(...replyNode.subThreads.filter(subThread => subThread !== nodeThreadId))
                if(replyNode.id === highlight) {
                    node.collapsed = replyNode.subThreads[0]
                    node.forceCollapse = replyNode.subThreads[0]
                }
                else if (replyNode.forceCollapse !== null) {
                    node.collapsed = replyNode.forceCollapse
                    node.forceCollapse = replyNode.forceCollapse
                }
                mergeParticipants(node.participants, replyNode.participants)
                // add author of reply to participants
                if (reply.authorName) {
                    mergeParticipants(node.participants, {
                        [reply.authorId]: {
                            latestMessageAt: reply.createdOn,
                            name: reply.authorName
                        }
                    })
                } else {
                    mergeParticipants(node.participants, {
                        incognito: {
                            latestMessageAt: reply.createdOn,
                            name: "Incognito"
                        }
                    })
                }
                // update last reply
                if (reply.body && (!node.latestMessage || reply.createdOn > node.latestMessage.createdOn)) {
                    node.latestMessage = {
                        ...reply,
                        body: reply.body
                    }
                }
                if (replyNode.latestMessage && (!node.latestMessage || replyNode.latestMessage.createdOn > node.latestMessage.createdOn)) {
                    node.latestMessage = replyNode.latestMessage
                }
            })
            return node
        }

        return getCommentNode(threadId, null)

    }, [comments, threadId, collapsed, highlight])

    const resetHighlightCallback = useCallback(() => {
        let node = commentTree as CommentNode | undefined
        const newCollapsed = {...collapsed}
        while(node && node.forceCollapse) {
            newCollapsed[node.id] = node.forceCollapse
            node = node.children.find(child => child.forceCollapse)
        }
        setCollapsed(newCollapsed)
        setHighlight(null)
    }, [collapsed, commentTree, setHighlight])

    const [strokeState, setStrokeState] = useState<strokeStates>(null)

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                width: "100%",
                position: "relative",
                boxSizing: "border-box",
            }}
        >
            <ChatCommentContainerMotion
                maxCommentLength={maxCommentLength}
                commentNode={commentTree}
                {...otherProps}
                onCollapse={onCollapse}
                // stroke
                strokeState={strokeState}
                setStrokeState={setStrokeState}
                isSingleChild={false}
                highlight={highlight}
                resetHighlight={resetHighlightCallback}
            />
        </Column>
    )
}
