import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {List, ListItemButton, ListItemText} from "@mui/material";
import {Thread} from "../../../slices/reduxTypes";
import {primaryLightBackground} from "../../../constants";
import {setSelectedIssueId, setWindowState} from "../pdfChannelAppSlice";
import {STROKE_COLOR} from "../comments/chatItems/utils/stroke";

export function ThreadOverviewList(props: {
    memberId: string
    filteredAndSortedThreads: Thread[],
    sendMessage: (data: { message: string, data: any }) => void
}) {

    useEffect(() => {
        // set question highlight id to null when we go to thread overview
        props.sendMessage({
            message: "setHighlightedIssueId",
            data: null
        })
    }, [props])

    const dispatch = useDispatch()

    return (
        <List dense disablePadding={true} sx={{mb: 1}}>
            {
                props.filteredAndSortedThreads.map((thread) => {
                    const pageIndex = " / page " + (thread.details.link.pageIndex + 1).toString()
                    const accepted = thread.solved !== undefined ? " / " + (thread.solved ? "resolved" : "unresolved") : ""
                    const viewed = thread.details.readBy.includes(props.memberId)
                    const unread = viewed ? " / read" : " / unread"
                    return (
                        <ListItemButton
                            key={thread.id}
                            sx={{
                                ...(!viewed) && {
                                    bgcolor: STROKE_COLOR
                                }
                            }}
                            onClick={() => {
                                props.sendMessage({
                                    message: "scrollIssueIntoView",
                                    data: thread.id
                                })
                                dispatch(setWindowState("minimized"))
                                dispatch(setSelectedIssueId(thread.id))
                            }}
                        >
                            <ListItemText primary={thread.details.title} secondary={
                                "by " + (thread.details.comments[thread.id].authorName ?? "Incognito")
                                + " on " + (new Date(thread.createdOn)).toDateString()
                                + " / " + thread.category.toLowerCase()
                                + pageIndex
                                + accepted
                                + unread
                            }/>
                        </ListItemButton>
                    )
                })
            }
        </List>
    )
}
