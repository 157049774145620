import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Tab, Tabs} from "@mui/material";
import {PdfUrlForm} from "./pdfUrlForm";
import {PdfFsForm} from "./pdfFsForm";
import {PdfCloudForm} from "./pdfCloudForm";
import {
    discunaCoreFrontendApiClient
} from "../../../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {createChannel, generateId} from "../../../discunaAppApi";
import {discunaAppFrontendApiHost} from "../../../../discunaAppToolkit/discunaAppFrontendCom/discunaAppFrontendApiHost";
import {mediaBufferManager} from "../../../mediaBufferManager";
import {PdfFetchInfo} from "../../../../shared";

enum methods {
    URL = 0,
    Cloud,
    FS,
}

export interface PdfData {
    name: string,
    // size: number,
    // path?: string,
    fingerprint: string,
    numPages: number,
    data: Uint8Array,
}

export function AddPDFChannel({communityId, parentId}: {
    communityId: string,
    parentId: string,
}) {

    // states
    const [method, setMethod] = useState(methods.URL)
    const [channelName, setChannelName] = useState("")
    const [pdfData, setPdfData] = useState<PdfData | null>(null)
    const [pdfFetchInfo, setPdfFetchInfo] = useState<PdfFetchInfo | null>(null)

    const resetStates = useCallback(() => {
        setChannelName("")
        setPdfData(null)
        setPdfFetchInfo(null)
    }, [])

    // tell discuna our page app has loaded
    useEffect(() => {
        discunaCoreFrontendApiClient.pageIsReady()
    }, [])

    const disabled = useMemo(() => channelName.length === 0 || !pdfData || !pdfFetchInfo, [channelName, pdfData, pdfFetchInfo])
    const disableCreateChannelButton = useRef(true)
    useEffect(() => {
        if (!disabled && disableCreateChannelButton.current) {
            disableCreateChannelButton.current = false
            discunaCoreFrontendApiClient.setDisableCreateChannelButton(false).catch((e) => {
                console.error(e)
            })
        } else if (disabled && !disableCreateChannelButton.current) {
            disableCreateChannelButton.current = true
            discunaCoreFrontendApiClient.setDisableCreateChannelButton(true).catch((e) => {
                console.error(e)
            })
        }
    }, [channelName, disabled])

    // listen to click event of create channel button
    useEffect(() => {
        return discunaAppFrontendApiHost.onCreateChannelClick(async () => {

            console.log("CREATE")

            if (!pdfData || !pdfFetchInfo || channelName.length === 0) return
            // const discunaDesktopAPI = window.discunaDesktopAPI

            const channelId = generateId()

            // Transfer PDF to the correct folder like when downloading it via URL
            // save file in buffer
            // we do not want to download data twice, especially when we are in the web.
            mediaBufferManager.addBuffer(`${communityId}/${channelId}`, pdfData.data)

            try {
                await createChannel({
                    communityId,
                    parentChannelId: parentId,
                    channelId,
                    details: {
                        permissions: {
                            write: {
                                roles: [],
                                userIds: []
                            },
                            read: {
                                roles: [],
                                userIds: []
                            }
                        },
                        fileId: pdfData.fingerprint,
                        numPages: pdfData.numPages,
                        pdfFetchInfo
                    },
                    name: channelName
                })
            } catch (e) {
                console.error("createChatChannel failed", e)
            }

            return channelId
        })
    }, [channelName, communityId, parentId, pdfData, pdfFetchInfo])

    return (
        <>
            <Tabs
                value={method}
                onChange={(ev, newVal) => {
                    resetStates()
                    setMethod(newVal)
                }}
                aria-label="Choose how to add the PDF"
            >
                <Tab label="URL" id={"tab-pdf-url"}/>
                <Tab label="Upload" id={"tab-pdf-cloud"}/>
                <Tab label="Local" id={"tab-pdf-fs"}/>
            </Tabs>
            {
                method === methods.URL &&
                <PdfUrlForm
                    channelName={channelName}
                    setChannelName={setChannelName}
                    pdfData={pdfData}
                    setPdfData={setPdfData}
                    setPdfFetchInfo={setPdfFetchInfo}
                />
            }
            {
                method === methods.Cloud &&
                <PdfCloudForm
                    channelName={channelName}
                    setChannelName={setChannelName}
                    pdfData={pdfData}
                    setPdfData={setPdfData}
                    setPdfFetchInfo={setPdfFetchInfo}
                />
            }
            {
                method === methods.FS &&
                <PdfFsForm
                    channelName={channelName}
                    setChannelName={setChannelName}
                    pdfData={pdfData}
                    setPdfData={setPdfData}
                    setPdfFetchInfo={setPdfFetchInfo}
                />
            }

        </>
    )
}
