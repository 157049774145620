import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from "./app/app";
import {store} from "./app/store";
import {Provider} from "react-redux";
import "./firebase/firebase"
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';


if(process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    // console.log("disable logging")
    console.log = console.warn = console.error = console.debug = () => {}
}

const isIFrame = window.parent !== window

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <App/>
            </Provider>
        </Router>
    </React.StrictMode>
)

serviceWorkerRegistration.register()


//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
