import {Match, RenderSearchProps, SearchPlugin} from '@react-pdf-viewer/search';
import React, {memo, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Checkbox, CircularProgress,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    TextField, Typography
} from "@mui/material";
import {KeyboardEvent} from "react";
import {Center, Column, Row} from "../../../common/motion_mui";

enum SearchStatus {
    NotSearchedYet,
    Searching,
    FoundResults,
}

interface MatchPageData {
    pageIndex: number,
    startMatchIndex: number,
    numMatches: number,
}

export const PdfSearch = memo((props: {
    searchPluginInstance: SearchPlugin;
}) => {


    const {Search} = props.searchPluginInstance;

    const clearKeywordRef = useRef<null | (() => void)>(null)
    useEffect(() => {
        return () => {
            if (clearKeywordRef.current !== null) clearKeywordRef.current()
        }
    }, [])

    return (
        <Search key={"searchPdf"}>
            {
                (renderSearchProps: RenderSearchProps) => {
                    clearKeywordRef.current = renderSearchProps.clearKeyword
                    return (
                        <RenderSearch {...renderSearchProps}/>
                    )
                }
            }
        </Search>

    )
}, (a, b) => true)


export const RenderSearch = memo((props: {
    currentMatch: number,
    clearKeyword: () => void,
    changeMatchCase: (matchCase: boolean) => void,
    changeWholeWords: (wholeWords: boolean) => void,
    keyword: string,
    setKeyword: (keyword: string) => void,
    jumpToMatch: (matchIndex: number) => Match | null,
    jumpToNextMatch: () => Match | null,
    jumpToPreviousMatch: () => Match | null,
    search: () => Promise<Match[]>
}) => {

    const {
        currentMatch,
        clearKeyword,
        changeMatchCase,
        changeWholeWords,
        keyword,
        setKeyword,
        jumpToMatch,
        jumpToNextMatch,
        jumpToPreviousMatch,
        search
    } = props;

    const [searchStatus, setSearchStatus] = useState(SearchStatus.NotSearchedYet);
    const [matches, setMatches] = useState<Match[]>([]);
    const hasResults = matches.length !== 0
    const [matchCase, setMatchCase] = useState(false)
    const [matchWholeWord, setMatchWholeWord] = useState(false)
    const [matchListItems, setMatchListItems] = useState<MatchPageData[]>([])

    const renderMatchSample = (matchData: MatchPageData) => {
        console.log("matchData", matchData)
        console.log("currentMatch", currentMatch)
        const secondary = matchData.numMatches.toString() + (matchData.numMatches === 1 ? " match" : " matches")
        const selected = (currentMatch > matchData.startMatchIndex) && (currentMatch <= matchData.startMatchIndex + matchData.numMatches)
        return (
            <ListItem selected={selected} button dense onClick={() => {
                console.log("jump to", matchData.startMatchIndex)
                jumpToMatch(matchData.startMatchIndex + matchData.numMatches)
            }}>
                <ListItemText
                    primary={"Page " + matchData.pageIndex}
                    secondary={secondary}
                />
            </ListItem>
        );
    };

    const performSearch = () => {
        search().then((matches) => {
            console.log("found results", matches)
            setSearchStatus(SearchStatus.FoundResults);
            setMatches(matches);
            // TODO compute list items
            const listItems = [] as MatchPageData[]
            if (matches.length > 0) {
                let currPageData = {
                    pageIndex: matches[0].pageIndex,
                    numMatches: 1,
                    startMatchIndex: 0,
                } as MatchPageData
                listItems.push(currPageData)
                for (let i = 1; i < matches.length; ++i) {
                    const match = matches[i]
                    if (match.pageIndex !== currPageData.pageIndex) {
                        currPageData = {
                            pageIndex: match.pageIndex,
                            numMatches: 1,
                            startMatchIndex: i,
                        }
                        listItems.push(currPageData)
                    } else {
                        currPageData.numMatches += 1
                    }
                }
            }
            console.log("found results listItems", listItems)
            setMatchListItems(listItems)
        }).catch((e) => {
            console.log("error", e)
        });
    }

    const inputRef = useRef<HTMLInputElement>()

    const handleSearchKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && keyword) {
            setSearchStatus(SearchStatus.Searching);
            const input = inputRef.current
            if(input) input.blur()
            performSearch()
        }
    };

    // useEffect(() => {
    //     performSearch()
    // }, [matchWholeWord, matchCase])


    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                flexGrow: 1,
                overflow: "auto",
            }}
        >
            <TextField
                inputRef={inputRef}
                color={"secondary"}
                id="searchPdf"
                label="keyword"
                variant="filled"
                style={{
                    margin: "4px 16px 8px 16px"
                }}
                value={keyword}
                onChange={(e) => setKeyword(e.currentTarget.value)}
                onKeyDown={handleSearchKeyDown}
            />

            <Column
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
                sx={{
                    margin: "0 16px 4px 16px"
                }}
            >

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={matchCase}
                            onChange={() => {
                                changeMatchCase(!matchCase)
                                setMatchCase(!matchCase)
                            }}
                            name="match case"/>
                    }
                    label="match case"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={matchWholeWord}
                            onChange={() => {
                                changeWholeWords(!matchWholeWord)
                                setMatchWholeWord(!matchWholeWord)
                            }}
                            name="whole words"
                        />
                    }
                    label="whole words"
                />
                <Box height={4}/>
                <Typography variant={"body2"} style={{
                    color: "gray"
                }}>The first query takes more time</Typography>
                <Box height={8}/>
                <Row mainAxisAlignment={"end"} crossAxisAlignment={"center"}>

                    <Button
                        onClick={() => {
                            clearKeyword()
                            setSearchStatus(SearchStatus.NotSearchedYet)
                            setMatches([])
                        }}
                    >
                        clear
                    </Button>
                    <Box width={8}/>
                    <Button
                        color={"secondary"}
                        onClick={() => {
                            setSearchStatus(SearchStatus.Searching)
                            performSearch()
                        }}
                    >
                        search
                    </Button>
                </Row>
            </Column>

            {
                searchStatus === SearchStatus.Searching && (
                    // <CircularProgress size={32}/>
                    <Center
                        style={{
                            margin: "16px",
                            flexGrow: 1,
                        }}
                    >
                        <CircularProgress color={"secondary"} size={32}/>
                    </Center>
                )
            }
            {
                searchStatus === SearchStatus.FoundResults && !hasResults && (
                    <Center
                        style={{
                            margin: "16px",
                            flexGrow: 1,
                        }}
                    >
                        <div style={{
                            color: "gray"
                        }}>
                            no results
                        </div>
                    </Center>

                )
            }
            {
                searchStatus === SearchStatus.FoundResults && hasResults && (
                    <List dense disablePadding={true}>
                        {
                            matchListItems.map(renderMatchSample)
                        }
                    </List>
                )
            }
        </Column>
    )
})
