import {Column} from "../common/motion_mui";
import React, {useCallback, useMemo, useState} from "react";
import {useMediaManager} from "../markdownEditor/useMediaManager";
import {createThread, createTitle, generateId} from "../../discunaAppApi";
import {useDispatch} from "react-redux";
import {MarkdownEditor} from "../markdownEditor/markdownEditor";
import {arraySavePush} from "../../../shared/utils/arrayOps";
import {TitleForm} from "../markdownEditor/titleForm";
import {EditorFooter} from "../markdownEditor/editorFooter";
import {MAX_CHAT_COMMENT_BODY_LENGTH} from "../../../shared";
import {Category, MAX_CHAT_THREAD_TITLE_LENGTH, PdfMarkerProps} from "../../../shared/thread";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps} from "@mui/material";
import {useAppDispatch} from "../../store";
import {CheckboxWithTooltip} from "../common/checkboxWithTooltip";


export function ThreadForm({channelId, communityId, getMediaFolderUrl, sx, autoFocus, link, onSend}:{
    channelId: string,
    communityId: string,
    getMediaFolderUrl: () => string,
    sx?: SxProps,
    autoFocus?: boolean,
    link: PdfMarkerProps,
    onSend: () => void,
}) {

    // states
    const [showFullEditor, setShowFullEditor] = useState(true)
    const [title, setTitle] = useState("")
    const [markAsSolved, setMarkAsSolved] = useState(false)
    const [category, setCategory] = useState<Category | "">("")
    const [categoryHasError, setCategoryHasError] = useState(false)
    const [body, setBody] = useState("")
    const [uploadedMedia, setUploadMedia] = useState<string[]>([])

    const [deleteUnusedMedia, deleteAllUploadedMedia] = useMediaManager()

    const dispatch = useAppDispatch()

    // callbacks
    const createTitleCallback = useCallback(async (text: string) => {
        const res = await createTitle({text})
        return {title: res.data.title}
    }, [])

    const resetStates = useCallback(() => {
        setBody("")
        setTitle("")
        setShowFullEditor(false)
        setCategory("")
        setCategoryHasError(false)
        setUploadMedia([])
    }, [])

    const uploadFolder = useMemo(getMediaFolderUrl, [getMediaFolderUrl])

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                // m: 2,
                ...sx
            }}
        >
            <MarkdownEditor
                maxMarkdownLength={MAX_CHAT_COMMENT_BODY_LENGTH}
                focusOnMount={Boolean(autoFocus)}
                markdown={body}
                onChange={(value) => {
                    setBody(value)
                }}
                onFocus={() => setShowFullEditor(true)}
                isActivated={showFullEditor}
                placeholder={"Post a new comment"}
                uploadDirectory={uploadFolder}
                onUploadMedia={(url) => {
                    setUploadMedia(arraySavePush(uploadedMedia, url))
                }}
            />
            {
                showFullEditor &&
                <>
                    {
                        <TitleForm
                            maxTitleLength={MAX_CHAT_THREAD_TITLE_LENGTH}
                            label={"Thread title"}
                            sx={{mt: 1}}
                            title={title}
                            setTitle={setTitle}
                            createTitle={createTitleCallback}
                            titleSeedText={body}
                        />
                    }
                    <FormControl sx={{mt: 1}} variant="outlined" error={categoryHasError}>
                        <InputLabel id="thread-category-input">Choose a category</InputLabel>
                        <Select
                            labelId="select-thread-category-label"
                            id="select-thread-category"
                            value={category}
                            onChange={(e) => {
                                console.log(e.currentTarget)
                                // @ts-ignore
                                setCategory(e.target.value)
                                setCategoryHasError(false)
                            }}
                            label="Choose a category"
                        >
                            <MenuItem value={""}>Choose a category</MenuItem>
                            <MenuItem value={"question"}>Question</MenuItem>
                            <MenuItem value={"error"}>Error</MenuItem>
                            <MenuItem value={"opinion"}>Opinion</MenuItem>
                            <MenuItem value={"suggestion"}>Suggestion</MenuItem>
                            <MenuItem value={"info"}>Info</MenuItem>
                        </Select>
                        {
                            categoryHasError &&
                            <FormHelperText>You must choose a category</FormHelperText>
                        }
                    </FormControl>
                    <CheckboxWithTooltip
                        sx={{alignSelf: "start", mt: 1}}
                        isChecked={markAsSolved}
                        setIsChecked={setMarkAsSolved}
                        label={"Mark as solved"}
                        tooltipTitle={"Check this box if your comment does not require further discussion. For example, if your comment serves as additional information or a clarification."}
                    />
                    <EditorFooter
                        hideCancel={true}
                        sendMode={"post"}
                        onSend={(data) => {
                            if(category !== "") {
                                deleteUnusedMedia(body, uploadedMedia)
                                dispatch(createThread({
                                    communityId,
                                    channelId,
                                    threadId: generateId(),
                                    title,
                                    body,
                                    category,
                                    incognito: !data.showName,
                                    link,
                                    markAsSolved
                                }));
                                resetStates()
                                onSend()
                            }
                        }}
                        onCancel={() => {
                            deleteAllUploadedMedia(uploadedMedia)
                            resetStates()
                        }}
                        sendDisabled={title.length === 0 || body.length === 0 || category === ""}
                    />
                </>
            }

        </Column>
    )
}
