import {compile, TypeOf, v} from "suretype";
import {idSchema} from "./utils/schema";
import {CommentFirestoreData, MAX_CHAT_COMMENT_BODY_LENGTH} from "./comments";

// ==========================
// constants
// ==========================

export const MAX_CHAT_THREAD_TITLE_LENGTH = 400


// ==========================
// pdf related
// ==========================

export type threadCategoryTypes = "question" | "error" | "opinion" | "suggestion" | "info"

export const markerPositionSchema = v.object({
    top: v.number().required(),
    left: v.number().required()
})
export type MarkerPosition = TypeOf<typeof markerPositionSchema>

export const pdfMarkerPropsSchema = v.object({
    position: markerPositionSchema.required(),
    pageIndex: v.number().required()
})
export type PdfMarkerProps = TypeOf<typeof pdfMarkerPropsSchema>


// ==========================
// data types
// ==========================

export interface ThreadFirestoreData {
    details: {
        title: string,
        subscribers: string[],
        comments: Record<string, CommentFirestoreData>
        link: PdfMarkerProps,
        readBy: string[],
    }

    // sort
    createdOn: number,

    // filter
    category: threadCategoryTypes,
    solved?: boolean
}

// ==========================
// utils
// ==========================

export function createThreadFromArgs(
    member: { id: string, name: string },
    args: CreateThreadArgs
) {
    const createdOn = (new Date()).getTime()
    return {
        details: {
            title: args.title,
            subscribers: [member.id],
            comments: {
                [args.threadId]: {
                    authorId: member.id,
                    authorName: (!args.incognito) ? member.name : null,
                    body: args.body,
                    createdOn,
                    updatedOn: null,
                    reactions: {},
                    replyTo: null,
                }
            },
            link: args.link,
            readBy: [member.id]
        },
        // sort
        createdOn,
        // filter
        category: args.category,
        solved: args.markAsSolved??false,
    } as ThreadFirestoreData
}


// ==========================
// action args
// ==========================

const categorySchema = v.string().enum("question", "error", "opinion", "suggestion", "info")

export type Category = TypeOf<typeof categorySchema>

export const createThreadArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    title: v.string().maxLength(MAX_CHAT_THREAD_TITLE_LENGTH).required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
    category: categorySchema.required(),
    markAsSolved: v.boolean(),
    incognito: v.boolean().required(),
    link: pdfMarkerPropsSchema.required()
})
export type CreateThreadArgs = TypeOf<typeof createThreadArgsSchema>
export const createThreadArgsValidator = compile(createThreadArgsSchema)

export const setThreadSolvedArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    solved: v.boolean().required()
})
export type SetThreadSolvedArgs = TypeOf<typeof setThreadSolvedArgsSchema>
export const setThreadSolvedArgsValidator = compile(setThreadSolvedArgsSchema)

export const toggleThreadSubscriptionArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    isSubscribed: v.boolean().required()
})
export type ToggleThreadSubscriptionArgs = TypeOf<typeof toggleThreadSubscriptionArgsSchema>
export const toggleThreadSubscriptionArgsValidator = compile(toggleThreadSubscriptionArgsSchema)

export const updateThreadTitleArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    title: v.string().maxLength(MAX_CHAT_THREAD_TITLE_LENGTH).required(),
})
export type UpdateThreadTitleArgs = TypeOf<typeof updateThreadTitleArgsSchema>
export const updateThreadTitleArgsValidator = compile(updateThreadTitleArgsSchema)

export const markThreadAsReadArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
})
export type MarkThreadAsReadArgs = TypeOf<typeof markThreadAsReadArgsSchema>
export const markThreadAsReadArgsValidator = compile(markThreadAsReadArgsSchema)


export const setThreadBookmarkArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    bookmark: v.string().enum("", "interesting", "todo").required()
})
export type SetThreadBookmarkArgs = TypeOf<typeof setThreadBookmarkArgsSchema>
export const setThreadBookmarkArgsValidator = compile(setThreadBookmarkArgsSchema)

export enum CommunityBookmarkType {
    solved = "solved",
    unsolved = "unsolved",
}
