import {v, compile} from "suretype"
import type {TypeOf} from "suretype"
import {idSchema} from "./utils/schema";

export interface CommentFirestoreData {
    authorId: string,
    authorName: string | null, // null if incognito
    body: string | null, // null if comment deleted
    createdOn: number,
    updatedOn: number | null,
    reactions: Record<string, string[]>, // <emoji, userIds>
    replyTo: string | null,
}

export function createCommentFromArgs(
    member: { id: string, name: string },
    args: CreateCommentArgs,
) {
    return {
        authorId: member.id,
        authorName: args.incognito ? null : member.name,
        body: args.body,
        reactions: {},
        createdOn: (new Date()).getTime(),
        updatedOn: null,
        replyTo: args.replyTo,
    } as CommentFirestoreData
}

// --------------------------
// constants
// --------------------------

export const MAX_CHAT_COMMENT_TITLE_LENGTH = 400

export const MAX_CHAT_COMMENT_BODY_LENGTH = 2000

// --------------------------
// create comment title
// --------------------------

export const createTitleArgsSchema = v.object({
    text: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
})
export type CreateTitleArgs = TypeOf<typeof createTitleArgsSchema>
export const createTitleArgsValidator = compile(createTitleArgsSchema)

// --------------------------
// create
// --------------------------

export const createCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
    replyTo: idSchema.required(),
    incognito: v.boolean().required(),
})
export type CreateCommentArgs = TypeOf<typeof createCommentArgsSchema>
export const createCommentArgsValidator = compile(createCommentArgsSchema)

// --------------------------
// delete
// --------------------------

export const deleteCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required()
})
export type DeleteCommentArgs = TypeOf<typeof deleteCommentArgsSchema>
export const deleteCommentArgsValidator = compile(deleteCommentArgsSchema)

// --------------------------
// update
// --------------------------

export const updateCommentArgsSchema = v.object({
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    body: v.string().maxLength(MAX_CHAT_COMMENT_BODY_LENGTH).required(),
})
export type UpdateCommentArgs = TypeOf<typeof updateCommentArgsSchema>
export const updateCommentArgsValidator = compile(updateCommentArgsSchema)


// --------------------------
// toggle reaction
// --------------------------

// NOTE we do not offer thumbs down symbol because
// instead of giving a thumbs down, one should give a reason
// why the corresponding post is bad and then thumbs up this comment
export const chatReactions = ["👍", "🔥", "🙏", "❤️", "😂", "😮", "😔", "😠"] as const
const chatReactionSchema = v.string().enum(...chatReactions)
export type chatReactionType = TypeOf<typeof chatReactionSchema>

export const toggleCommentReactionArgsSchema = v.object({
    // cmd: v.string().const("toggleChatCommentReaction").required(),
    communityId: idSchema.required(),
    channelId: idSchema.required(),
    threadId: idSchema.required(),
    commentId: idSchema.required(),
    reaction: chatReactionSchema.required(),
    value: v.boolean().required()
})
export type ToggleCommentReactionArgs = TypeOf<typeof toggleCommentReactionArgsSchema>
export const toggleCommentReactionArgsValidator = compile(toggleCommentReactionArgsSchema)





