import React, {useState} from "react";
import {AvatarMotion, MotionBox} from "./motion_mui";
import {alpha, Box, SxProps} from "@mui/material";


export function AvatarButton({sx, size="small", onClick, disabled,children}: React.PropsWithChildren<{
    sx?: SxProps,
    size?: "medium" | "small",
    onClick?: () => void,
    disabled?: boolean
}>) {
    const [isHovered, setIsHovered] = useState(false)
    const [isPressed, setIsPressed] = useState(false)

    const diameter = size === "small" ? 32 : (size === "medium" ? 40 : 48)

    return (
        <>
            <Box
                sx={{
                    position: "absolute",
                    bgcolor: "white",
                    minWidth: diameter,
                    minHeight: diameter,
                    maxWidth: diameter,
                    maxHeight: diameter,
                    borderRadius: "50%",
                }}
            />
            <AvatarMotion

                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => {
                    setIsHovered(false)
                    setIsPressed(false)
                }}
                onClick={() => {
                    if(!disabled && onClick) onClick()
                }}
                onMouseDown={(e) => {
                    setIsPressed(true)
                }}
                onMouseUp={() => setIsPressed(false)}
                animate={{
                    scale: !isPressed && !disabled && isHovered ? 1.2 : 1,
                }}
                sx={{
                    cursor: disabled ? "default" : "pointer",
                    // position: "absolute",
                    bgcolor: (theme) => disabled ? theme.palette.action.disabledBackground : "primary.main",
                    color: (theme) => theme.palette.getContrastText(theme.palette.primary.main),
                    minWidth: diameter,
                    minHeight: diameter,
                    maxWidth: diameter,
                    maxHeight: diameter,
                    transition: (theme) => theme.transitions.create(
                        ['background-color', 'box-shadow', 'border-color', 'color'],
                        {
                            duration: theme.transitions.duration.short,
                        },
                    ),
                    ...sx,
                }}
            >
                {children}
            </AvatarMotion>
        </>
    )
}
