import {Button, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {popupModes} from "./popup";
import {Row} from "./motion_mui";


export function PopupHeader(props: {
    title: React.ReactNode | undefined,
    leftButton?: React.ReactNode,
    rightButton?: React.ReactNode,
    titleClickCallback?: () => void,
    onTitleMouseEnter?: () => void,
    onTitleMouseLeave?: () => void,
    inboxFromId?: string
    windowHeightWhenMinimized: number,
    windowState: popupModes
}) {

    // constant font size for title because the title bar has constant height, too
    const titleFontSize = "20px"

    useEffect(() => {
        if(props.windowState !== "minimized" && props.onTitleMouseLeave) props.onTitleMouseLeave()
    }, [props.windowState, props])

    const isExpanded = props.windowState === "expanded" || Boolean(props.inboxFromId)

    return (
        <Row mainAxisAlignment={"start"} crossAxisAlignment={"start"}

             sx={{
                 padding: "0px 20px",
                 maxWidth: "100%",
                 overflow: "hidden",
                 ...(!isExpanded) && {
                     maxHeight: props.windowHeightWhenMinimized + "px",
                     minHeight: props.windowHeightWhenMinimized + "px",
                 }
             }}>

            {
                <Row sx={{
                    minHeight: props.windowHeightWhenMinimized + "px",
                    maxHeight: props.windowHeightWhenMinimized + "px",
                }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                    {props.leftButton}
                </Row>
            }
            {
                !isExpanded ?

                    <Button
                        onMouseEnter={props.onTitleMouseEnter}
                        onMouseLeave={props.onTitleMouseLeave}
                        style={{
                            textTransform: 'none',
                            borderRadius: "0px",
                            flexGrow: 1,
                            padding: "0px",
                            margin: "0px",
                            alignItems: "start",
                            justifyContent: "start",
                            minHeight: props.windowHeightWhenMinimized + "px",
                            maxHeight: props.windowHeightWhenMinimized + "px"
                        }}
                        onClick={() => {
                            if (props.titleClickCallback) props.titleClickCallback()
                        }}
                    >
                        <Row sx={{
                            flexGrow: 1,
                            overflow: "hidden",
                            margin: "13px 2px 0px"
                        }} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                            <Typography component={"span"} style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                fontSize: titleFontSize,
                                color: "initial",
                                maxWidth: "100%",
                                overflow: "hidden",
                            }} variant={"h4"}>
                                {props.title}
                            </Typography>
                        </Row>
                    </Button> :
                    <Row sx={{
                        flexGrow: 1,
                        margin: "13px 2px 0px",
                        overflow: "hidden",
                        // 18 + 16 + 16 = 50
                        // but looks better with 14 because then it is aligned with menu icon
                    }} mainAxisAlignment={"start"} crossAxisAlignment={"start"}>
                        <Typography component={"span"} variant={"h4"} style={{
                            fontSize: titleFontSize,
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                            overflow: "hidden"
                        }}>
                            {props.title}
                        </Typography>
                    </Row>
            }
            {
                <Row sx={{
                    minHeight: props.windowHeightWhenMinimized + "px",
                    maxHeight: props.windowHeightWhenMinimized + "px",
                }} mainAxisAlignment={"start"} crossAxisAlignment={"center"}>
                    {props.rightButton}
                </Row>
            }
        </Row>
    )
}
