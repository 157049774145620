import {SxProps} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {Column} from "../../common/motion_mui";
import {MarkdownEditor} from "../../markdownEditor/markdownEditor";

import {useMediaManager} from "../../markdownEditor/useMediaManager";
import {EditorFooter} from "../../markdownEditor/editorFooter";
import {arraySavePush} from "../../../../shared/utils/arrayOps";

export interface ChatPostData {
    body: string,
    showName: boolean,
}

export function CommentForm(
    {
        sx,
        onSend,
        onCancel,
        focusOnMount,
        mode = "post",
        initialValue,
        upliftedState,
        getMediaFolderUrl,
        placeholder,
        showFullEditorInitially,
        hideCancel,
        maxCommentLength
    }: {
        sx?: SxProps,
        onSend: (data: ChatPostData) => void,
        onCancel?: () => void,
        mode?: "post" | "edit",
        upliftedState?: {
            body: string,
            setBody: (value: string) => void,
            showName: boolean,
            setShowName: (value: boolean) => void,
        },
        maxCommentLength?: number,
        hideCancel?: boolean,
        focusOnMount?: boolean,
        initialValue?: string,
        getMediaFolderUrl: () => string,
        placeholder?: string,
        showFullEditorInitially?: boolean
    }
) {

    const [showFullEditor, setShowFullEditor] = useState(Boolean(showFullEditorInitially))
    const [body, setBody] = useState(initialValue ?? "")
    const [showName, setShowName] = useState(true)
    const [uploadedMedia, setUploadMedia] = useState<string[]>([])

    const resetStates = useCallback(() => {
        if (upliftedState) {
            upliftedState.setBody("")
            upliftedState.setShowName(true)
        } else {
            setBody("")
            setShowName(true)
        }
        setUploadMedia([])
        setShowFullEditor(false)
    }, [upliftedState])

    const uploadFolder = useMemo(getMediaFolderUrl, [getMediaFolderUrl])

    const [deleteUnusedMedia, deleteAllUploadedMedia] = useMediaManager()

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={sx}
        >
            <MarkdownEditor
                maxMarkdownLength={maxCommentLength}
                focusOnMount={focusOnMount}
                markdown={upliftedState ? upliftedState.body : body}
                onChange={(value) => {
                    if (upliftedState) upliftedState.setBody(value)
                    else setBody(value)
                }}
                onFocus={() => setShowFullEditor(true)}
                isActivated={showFullEditor}
                placeholder={placeholder ?? "Post a new comment"}
                uploadDirectory={uploadFolder ?? undefined}
                onUploadMedia={(url) => {
                    setUploadMedia(arraySavePush(uploadedMedia, url))
                }}
            />
            {
                showFullEditor &&
                <EditorFooter
                    sendMode={mode}
                    hideCancel={hideCancel}
                    onSend={(data) => {
                        deleteUnusedMedia(upliftedState?.body ?? body, uploadedMedia)
                        onSend(upliftedState ?
                            {body: upliftedState.body, showName: upliftedState.showName} :
                            {body, showName})
                        resetStates()
                    }}
                    onCancel={() => {
                        deleteAllUploadedMedia(uploadedMedia)
                        if (onCancel) onCancel()
                        resetStates()
                    }}
                    upliftedState={upliftedState}
                    sendDisabled={upliftedState ? upliftedState.body.length === 0 : body.length === 0}
                />
            }
        </Column>
    )
}
