import {useSelector} from "react-redux";
import React, {useCallback, useRef, useState} from "react";
import {Divider, InputBase} from "@mui/material";
import {Center, Column} from "../../common/motion_mui";
import {RootState} from "../../../store";

export function PageNavigation({numPages, jumpToPage}: {
    numPages: number,
    jumpToPage: (pageIndex: number) => void
}) {

    const currentPageIndex = useSelector((state: RootState) => state.pdfViewer.currPageIndex)

    const inputRef = useRef<HTMLInputElement>()
    const [pageNumInput, setPageNumInput] = useState<string | null>(null)

    const handlePageNumKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (pageNumInput) {

            const pageIndex = Number(pageNumInput)
            console.log("page num", pageIndex, numPages)
            if (e.key === "Enter" && pageIndex >= 1 && pageIndex <= numPages) {
                console.log("jump to page", pageIndex - 1)
                jumpToPage(pageIndex - 1)
                setPageNumInput(null)
                const input = inputRef.current
                if (input) input.blur()
            }
        }
    }, [numPages, jumpToPage, pageNumInput])


    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{
                marginTop: "6px" // we need 12px padding, input has already 6px
            }}
        >

            <Center style={{
                minWidth: "40px",
                maxWidth: "40px",
                minHeight: "32px",
                maxHeight: "32px",
                textAlign: "center"
            }}>
                <InputBase
                    inputRef={inputRef}
                    inputProps={{
                        style: {
                            textAlign: "center",
                        }
                    }}
                    onKeyDown={handlePageNumKeyDown}
                    onChange={(val) => {
                        const value = val.currentTarget.value
                        if (value.length === 0) setPageNumInput("")
                        else {
                            const pageNum = Number(value)
                            if (numPages) {
                                if (pageNum >= 1 && pageNum <= numPages) {
                                    setPageNumInput(value)
                                }
                            }
                        }
                    }}
                    onFocus={() => {
                        setPageNumInput((currentPageIndex + 1).toString())
                    }}
                    onBlur={() => {
                        setPageNumInput(null)
                    }}
                    value={pageNumInput !== null ? pageNumInput : currentPageIndex + 1}
                />
            </Center>
            <Divider orientation={"horizontal"} style={{
                margin: "2px",
                backgroundColor: "black"
            }}/>
            <Center style={{
                minWidth: "40px",
                maxWidth: "40px",
                minHeight: "32px",
                maxHeight: "32px",
                // backgroundColor: theme.palette.secondary.main,
                // borderTop: "1px solid black",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
                textAlign: "center"
            }}>
                {numPages}
            </Center>
        </Column>
    )
}
