// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO later
// import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCpGwKWhftVpnoZT7dizQ2XkhCvIdhJRnc",
    authDomain: "discuna-app-pdf.firebaseapp.com",
    projectId: "discuna-app-pdf",
    storageBucket: "discuna-app-pdf.appspot.com",
    messagingSenderId: "88767671894",
    appId: "1:88767671894:web:f46c93cf5a2480dd5755f7"
};

// initialize Firebase apps
export const firebaseApp = initializeApp(firebaseConfig);

// TODO later
// const analytics = getAnalytics(app);

// get Firebase apps
export const firebaseAuth = getAuth();
export const firestoreDb = getFirestore();
export const storage = getStorage();
export const functions = getFunctions(firebaseApp, "europe-west3")


// check if dev mode
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    connectAuthEmulator(firebaseAuth, "http://127.0.0.1:19099", { disableWarnings: true });
    connectFirestoreEmulator(firestoreDb, '127.0.0.1', 18080);
    connectStorageEmulator(storage, "127.0.0.1", 19199);
    connectFunctionsEmulator(functions, "127.0.0.1", 15001);
}

