import {PDFDocument, PDFRawStream, PDFRef} from "pdf-lib";
// import base62 from "base62";
// import {PdfJs, VerifyPassword} from "@react-pdf-viewer/core";
import * as PdfJsApi from 'pdfjs-dist';
import {PDFDocumentProxy} from "pdfjs-dist";
import {mergeUint8Arrays} from "../../../shared/utils/arrayOps";
// import {PDFDocumentProxy} from "pdfjs-dist/types/display/api";


/**
 * convert url to id
 *
 * @param {string} url
 * @return {string}
 */
export function url2id(url: string): string {
    return url.replace(/[^A-Za-z0-9]/g, "_");
}


export async function generateHexHashFromUint8Array(arr: Uint8Array) {
    const hashBuffer = await crypto.subtle.digest('SHA-256', arr);
    // convert buffer to byte array
    // const hashArray = Array.from(new Uint8Array(hashBuffer));
    // hashArray.length = 32 because 8 (bit) * 32 = 256 bit
    // coincidentally, 2^8 = 256
    // console.log("hashArray length", hashArray.length)
    // convert bytes to hex string
    // return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    // 0-9 + a-z + A-Z = 10 + 2 * 26 = 62
    // const hash = hashArray.map((b) => base62.encode(b)).join('')
    return uint8ToHash(new Uint8Array(hashBuffer))
}

export function uint8ToHash(uint8: Uint8Array) {
    const arr = Array.from(uint8)
    let hash = ""
    // base62.setCharacterSet("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ._") // 64 characters
    for (let i = 0; i < arr.length; i += 3) {
        // 62 * 62 = 3844
        // 2^8 * 2^8 = 64
        // 3 * 8 = 24 bits can be represented with 4 base64 characters
        // because 256^3 = (2^8)^3 = 2^24 = (2^6)^4 = 64^4
        let bitString = ""
        const arrSlice = arr.slice(i, i + 3)
        for (let num of arrSlice) bitString += num.toString(2).padStart(8, '0')
        const num = parseInt(bitString, 2)
        // const hashSlice = base62.encode(num) // should result in 4 characters except the last 2 items of the array
        const hashSlice = numberToBase64(num).padStart(4, 'A')
        hash += hashSlice
    }
    return hash
}


// https://stackoverflow.com/questions/6213227/fastest-way-to-convert-a-number-to-radix-64-in-javascript/6573119
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789._";
// binary to string lookup table
const b2s = alphabet.split('');

// string to binary lookup table
// 123 == 'z'.charCodeAt(0) + 1
const s2b = new Array(123);
for (let i = 0; i < alphabet.length; i++) {
    s2b[alphabet.charCodeAt(i)] = i;
}

// number to base64
// TODO check that this code is actually correct
function numberToBase64(number: number): string {
    if (number < 0) return `-${numberToBase64(-number)}`;

    let lo = number >>> 0;
    let hi = (number / 4294967296) >>> 0;

    let right = "" as string;
    while (hi > 0) {
        right = b2s[0x3f & lo] + right;
        lo >>>= 6;
        lo |= (0x3f & hi) << 26;
        hi >>>= 6;
    }

    let left = "" as string;
    do {
        left = b2s[0x3f & lo] + left;
        lo >>>= 6;
    } while (lo > 0);

    return left + right;
}

// base64 to number
// function base64ToNumber(base64: string) {
//     let number = 0;
//     const sign = base64.charAt(0) === '-' ? 1 : 0;
//
//     for (let i = sign; i < base64.length; i++) {
//         number = number * 64 + s2b[base64.charCodeAt(i)];
//     }
//
//     return sign ? -number : number;
// }


export async function loadPDfFromData(props: {
    pdfData: Uint8Array,
    onError?: (error: string) => void,
    // onPassword?: (verifyPassword: VerifyPassword, reason: number) => void,
    onProgress?: (progress: { loaded: number, total: number }) => void,
}) {
    // const worker = new PdfJsApi.PDFWorker({name: `PDFWorker_${Date.now()}`}) as PdfJs.PDFWorker;

    // const params: PdfJs.GetDocumentParams = Object.assign(
    //     {
    //         withCredentials: false,
    //         worker,
    //     },
    //     {data: props.pdfData},
    //     {}
    // );

    const loadingTask = PdfJsApi.getDocument(props.pdfData);
    // loadingTask.onPassword
    // loadingTask.onProgress = props.onProgress;
    let doc = null as null | PDFDocumentProxy
    let error = null as any
    let success = true
    try {
        doc = await loadingTask.promise
    } catch (e) {
        success = false
        error = e
    } finally {
        await loadingTask.destroy()
        // worker.destroy()
    }

    return {
        success,
        error,
        doc
    }
}

export type PdfInfo = {
    numPages: number,
    fingerprint: string
}

export async function getPdfInfo(arr: Uint8Array): Promise<PdfInfo> {
    // const pdfDoc = await PDFDocument.load(arr);
    const {doc, ...rest} = await loadPDfFromData({pdfData: arr})
    if(!rest.success) console.error("getPdfInfo error", rest.error)
    if (!doc) throw Error("could not load document")
    const fingerprint = doc.fingerprints[0]

    // TODO doc.getMetadata() never resolves
    // const meta = await doc.getMetadata()
    // console.log("meta", meta.info)
    // const title = pdfDoc.getTitle()??null
    return {
        // title,
        numPages: doc.numPages,
        fingerprint
    }
}

export async function generateIdsFromPdfArray(arr: Uint8Array) {
    const pdfDoc = await PDFDocument.load(arr);
    const {doc} = await loadPDfFromData({pdfData: arr})
    if (!doc) throw Error("could not load document")
    const pages = pdfDoc.getPages();
    const pdfId = doc.fingerprints[0]
    const title = pdfDoc.getTitle()??null
    let pageIds = [] as string[]
    const promises = [] as Promise<void>[]
    for (const page of pages) {
        const contents = page.node.normalizedEntries().Contents
        if (!contents) throw Error("PDF has a page without contents")
        let contentStreams = [] as Uint8Array[]
        for (const streamRef of contents.asArray()) {
            if (streamRef instanceof PDFRef) {
                const stream = page.doc.context.lookup(streamRef);
                if (stream instanceof PDFRawStream) {
                    contentStreams.push(stream.contents)
                }
            }
        }
        if (contentStreams.length === 0) throw Error("PDF has a page without PDFRawStreams")
        let mergedContentStream = mergeUint8Arrays(contentStreams)
        promises.push(generateHexHashFromUint8Array(mergedContentStream)
            .then((pageId) => {
                pageIds.push(pageId)
            })
        )
    }
    await Promise.all(promises)
    // const textEncoder = new TextEncoder()
    // const mergedPageIds = textEncoder.encode(pageIds.join(""));
    // const pdfId = await generateHexHashFromUint8Array(mergedPageIds)
    return {
        title,
        pdfId,
        pageIds
    }

}
