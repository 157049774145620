

// ==========================
// Types
// ==========================

// --------------------------
// MouseClickInPage Event
// --------------------------

type mouseClickInPage = "mouseClickInPage"

export interface MouseClickInPageEventPayload {
    pageIndex: number,
    // position relative to PDF page
    pagePosition: {
        top: number
        left: number
    },
}

// --------------------------
// MouseClickInPage Event
// --------------------------

type mouseEntersPage = "mouseEntersPage"

export interface MouseEntersPageEventPayload {
    pageIndex: number,
}

// --------------------------
// MouseClickInPage Event
// --------------------------

type mouseLeavesPage = "mouseLeavesPage"

export interface MouseLeavesPageEventPayload {
    pageIndex: number,
}

// --------------------------
// Type utils
// --------------------------

type eventNames = mouseClickInPage | mouseEntersPage | mouseLeavesPage

type PayloadType<T extends eventNames> =
    T extends mouseClickInPage ? MouseClickInPageEventPayload :
        T extends mouseEntersPage ? MouseEntersPageEventPayload :
            T extends mouseLeavesPage ? MouseLeavesPageEventPayload :
                never

type CallbackType<T extends eventNames> = (payload: PayloadType<T>) => void


// ==========================
// PdfViewerEventManager
// ==========================

class PdfViewerEventManager {
    mouseClickInPage = new Set<CallbackType<mouseClickInPage>>([])
    mouseEntersPage = new Set<CallbackType<mouseEntersPage>>([])
    mouseLeavesPage = new Set<CallbackType<mouseLeavesPage>>([])

    subscribe<T extends eventNames>(event: T, callback: CallbackType<T>) {
        // TODO is there a nicer way?
        this[event].add(callback as any)
    }

    unsubscribe<T extends eventNames>(event: T, callback: CallbackType<T>) {
        this[event].delete(callback as any)
    }

    _emit<T extends eventNames>(event: T, payload: PayloadType<T>) {
        const callbacks = Array.from(this[event] as any) as CallbackType<T>[]
        for(const callback of callbacks) callback(payload)
    }
}

export const pdfViewerEventManager = new PdfViewerEventManager()

// @ts-ignore
window.pdfViewerEventManager = pdfViewerEventManager