import type {PdfJs} from "@react-pdf-viewer/core";
import {IconButton, List} from "@mui/material";
import React, {useEffect, useState} from "react";
import {nanoid} from "@reduxjs/toolkit";
import {NestedListItem} from "./bookmarkItem";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import {DestinationOffsetFromViewport, getDestination, SpecialZoomLevel} from "@react-pdf-viewer/core";

interface OutlineItem {
    title: string,
    type: "leafChapter" | "chapter",
    id: string,
    level: number,
    open?: boolean,
    dest?: PdfJs.OutlineDestinationType
}

export function flattenOutline(level: number, outline: PdfJs.Outline[] | null) {
    if (outline === null) return [] as OutlineItem[]
    const flattenedOutline = [] as OutlineItem[]
    outline.forEach((item) => {
        if (item.items.length === 0) {
            flattenedOutline.push({
                id: nanoid(),
                title: item.title,
                type: "leafChapter",
                level: level,
                ...(item.dest) && {dest: item.dest}
            })
        } else {
            flattenedOutline.push({
                id: nanoid(),
                title: item.title,
                type: "chapter",
                open: false,
                level: level,
                ...(item.dest) && {dest: item.dest}
            })
            flattenedOutline.push(...flattenOutline(level + 1, item.items))
        }
    })
    return flattenedOutline
}

function getVisibleOutlineItems(flattenedOutline: OutlineItem[]) {
    let parentClosedFolderLevel: null | number = 0;
    let visibleOutlineItems = [] as OutlineItem[]
    for (const outlineItem of flattenedOutline) {
        if (parentClosedFolderLevel === null) {
            visibleOutlineItems.push(outlineItem)
            if (!outlineItem.open) {
                parentClosedFolderLevel = outlineItem.level
            }
        } else {
            if (outlineItem.level <= parentClosedFolderLevel) {
                visibleOutlineItems.push(outlineItem)
                if (outlineItem.open) {
                    parentClosedFolderLevel = null
                } else {
                    parentClosedFolderLevel = outlineItem.level
                }
            }
        }
    }
    return visibleOutlineItems
}

export type jumpToDestinationType = ((
    pageIndex: number,
    bottomOffset: number | DestinationOffsetFromViewport,
    leftOffset: number | DestinationOffsetFromViewport,
    scaleTo?: number | SpecialZoomLevel
) => void)

export const Bookmarks = React.memo((props: {
    jumpToDestination: jumpToDestinationType | null,
    doc: PdfJs.PdfDocument | null,
    outline: PdfJs.Outline[] | null
}) => {
    const outline = props.outline
    const jumpToDestination = props.jumpToDestination
    const [selectedBookmark, setSelectedBookmark] = useState<string | null>(null)
    const [flattenedOutline, setFlattenedOutline] = useState([] as OutlineItem[])
    useEffect(() => {
        setFlattenedOutline(flattenOutline(0, outline))
    }, [outline])
    // const flattenedOutline = useMemo(() => flattenOutline(0, outline), [outline])
    if (outline === null) return null

    const visibleFlattenedOutline = getVisibleOutlineItems(flattenedOutline)

    // outline.forEach((item) => {
    //     item.
    // })
    return (
        <List dense
              disablePadding
              style={{
                  width: "fit-content",
                  minWidth: "100%",
                  height: "100%",
              }}>
            {visibleFlattenedOutline.map(
                (outline) => {
                    switch (outline.type) {
                        case "leafChapter":
                            return (
                                <NestedListItem
                                    key={outline.id}
                                    id={outline.id}
                                    name={outline.title}
                                    level={outline.level}
                                    selected={outline.id === selectedBookmark}
                                    onClick={() => {
                                        if(props.doc && outline.dest && jumpToDestination) {
                                            getDestination(props.doc, outline.dest).then((target) => {
                                                const { pageIndex, bottomOffset, scaleTo } = target;
                                                jumpToDestination(pageIndex, bottomOffset, 0, scaleTo);
                                            });
                                        }
                                        setSelectedBookmark(outline.id)
                                    }}
                                />
                            )
                        case "chapter":
                            return (
                                <NestedListItem
                                    key={outline.id}
                                    id={outline.id}
                                    name={outline.title}
                                    level={outline.level}
                                    selected={outline.id === selectedBookmark}
                                    onClick={() => {
                                        if(props.doc && outline.dest && jumpToDestination) {
                                            getDestination(props.doc, outline.dest).then((target) => {
                                                const { pageIndex, bottomOffset, scaleTo } = target;
                                                jumpToDestination(pageIndex, bottomOffset, 0, scaleTo);
                                            });
                                        }
                                        setSelectedBookmark(outline.id)
                                    }}
                                    icon={
                                        <IconButton
                                            size={"small"}
                                            edge={"start"}
                                            onClick={() => {
                                                outline.open = !outline.open
                                                // TODO why exactly do we need to copy here?
                                                setFlattenedOutline([...flattenedOutline])
                                            }}>
                                            {
                                                outline.open ?
                                                    <ExpandMoreOutlinedIcon/> :
                                                    <ChevronRightOutlinedIcon/>
                                            }
                                        </IconButton>
                                    }
                                />
                            )
                    }
                }
            )}
        </List>
    )
})
