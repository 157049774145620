import {IconButton, Typography} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React from "react";
import {Column, ColumnMotion, Row} from "../../common/motion_mui";

export function SidebarContainer(props: {
    sidebarItem: React.ReactNode,
    visible: boolean,
    title: React.ReactNode,
    onClose: () => void
}){
    return (
        <Column
            sx={{
                position: "fixed",
                left: 0,
                top: 0,
                minHeight: "100vh",
                maxHeight: "100vh",
                minWidth: "300px",
                maxWidth: "300px",
                overflow: "hidden",
                zIndex: 2,
                backgroundColor: "#F9FCFF",
                visibility: props.visible ? "visible" : "hidden"
            }}
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
        >
            <ColumnMotion
                animate={{
                    y: props.visible ? 0 : 20
                }}
                initial={{
                    y: 20
                }}
                style={{
                    position: "fixed",
                    left: 0,
                    top: 0,
                    minHeight: "100vh",
                    maxHeight: "100vh",
                    minWidth: "300px",
                    maxWidth: "300px",
                    overflowY: "auto",
                }}
                mainAxisAlignment={"start"}
                crossAxisAlignment={"stretch"}
            >
                <Row
                    mainAxisAlignment={"spaceBetween"}
                    crossAxisAlignment={"center"}
                    sx={{
                        margin: "16px 16px 8px 16px",
                    }}
                >
                    <Typography variant={"h4"}>
                        {
                            props.title
                        }
                    </Typography>
                    <IconButton
                        // className={classes.icon}
                        size={"small"}
                        edge={"end"}
                        onClick={() => {
                            props.onClose()
                        }}>
                        <CloseOutlinedIcon/>
                    </IconButton>
                </Row>
                {
                    props.sidebarItem
                }
            </ColumnMotion>
        </Column>
    )
}
