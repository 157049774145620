import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {setInAddMode} from "../pdfViewerSlice";
import {
    MouseClickInPageEventPayload,
    pdfViewerEventManager
} from "../pdfViewerEventManager";
import {Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {motion} from "framer-motion";
import {RootState} from "../../../../store";


export function AddPdfMarker({scale}: {
    scale: number
}) {

    const dispatch = useDispatch()

    // handle keyboard events
    useEffect(() => {
        const eventHandler = (e: KeyboardEvent) => {
            if (e.key === "Escape") dispatch(setInAddMode(false))
        }
        window.addEventListener('keydown', eventHandler)
        return () => {
            window.removeEventListener('keydown', eventHandler)
        }
    }, [dispatch])


    return (
        <FabPDFMotion
            animate={{}}
            scale={scale}
            onClick={() => {
            }}
        />
    )
}

const FabPDF = React.forwardRef<any, React.PropsWithChildren<{
    scale: number,
    onClick: () => void
}>>((props, ref) => {

    const [position, setPosition] = useState<{ x: number, y: number }>({x: 0, y: 0})
    const isInAddMode = useSelector((state: RootState) => state.pdfViewer.inAddMode)
    // console.log("position", position, `translate(${position.x - 23}px, ${position.y - 23}px)`, isInAddMode)
    const dispatch = useDispatch()

    const [isOnPage, setIsOnPage] = useState(false)

    useEffect(() => {
        if (isInAddMode) {
            const pageLeave = () => setIsOnPage(false)
            const pageEnter = () => setIsOnPage(true)
            const pageClick = (payload: MouseClickInPageEventPayload) => {
                dispatch(setInAddMode(false))
                window.postMessage({
                    message: "addPDFIssue",
                    data: {
                        link: {
                            position: payload.pagePosition,
                            pageIndex: payload.pageIndex
                        },
                    }
                }, "*")
            }
            pdfViewerEventManager.subscribe("mouseLeavesPage", pageLeave)
            pdfViewerEventManager.subscribe("mouseEntersPage", pageEnter)
            pdfViewerEventManager.subscribe("mouseClickInPage", pageClick)
            return () => {
                pdfViewerEventManager.unsubscribe("mouseLeavesPage", pageLeave)
                pdfViewerEventManager.unsubscribe("mouseEntersPage", pageEnter)
                pdfViewerEventManager.unsubscribe("mouseClickInPage", pageClick)
            }
        }

    }, [dispatch, isInAddMode])

    useEffect(() => {
        if (isInAddMode) {
            const mouseMoveHandler = (e: MouseEvent) => {
                setPosition({x: e.clientX, y: e.clientY,})
            }
            window.addEventListener("mousemove", mouseMoveHandler)
            return () => {
                window.removeEventListener("mousemove", mouseMoveHandler)
            }
        }
    }, [isInAddMode])


    return (
        <div
            style={{
                position: "fixed",
                minHeight: "46px",
                maxHeight: "46px",
                minWidth: "46px",
                maxWidth: "46px",
                ...(!isInAddMode) && {
                    right: "5px",
                    // download button is hidden in desktop app
                    top: "379px",
                    // top: window.discunaDesktopAPI ? "339px" : "379px"
                    // bottom: "calc(50% - 23px)",
                },
                ...(isInAddMode) && {
                    pointerEvents: "none",
                    cursor: "move",
                    transform: `translate(${position.x - 23}px, ${position.y - 23}px)`,
                    left: 0,
                    top: 0,
                    ...(isOnPage) && {
                        pointerEvents: "none",
                        cursor: "none",
                    }
                },
            }}
        >

            <Fab ref={ref}
                 onClick={(e) => {
                     if (!isInAddMode) {
                         console.log("click", e)
                         // props.onClick()
                         setPosition({x: e.clientX, y: e.clientY,})
                         dispatch(setInAddMode(true))
                         setIsOnPage(false)
                     }
                 }}
                 disableRipple
                 color="primary"
                 aria-label="add"
                 style={{
                     position: "relative",
                     minHeight: "46px",
                     maxHeight: "46px",
                     minWidth: "46px",
                     maxWidth: "46px",
                     transition: "all 300ms",
                     transform: `scale(${isInAddMode ? props.scale : 1})`,
                     ...(isInAddMode) && {
                         pointerEvents: "none",
                         cursor: "none",
                         ...(isOnPage) && {
                             backgroundColor: "rgba(0, 99, 220, 0.7)"// "rgba(97, 106, 107, 0.7)"
                         }
                     },
                 }}
            >
                {
                    !isInAddMode &&
                    <AddIcon/>
                }
            </Fab>
        </div>
    )
})

const FabPDFMotion = motion(FabPDF)
