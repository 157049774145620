import React, {useCallback, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Box,
    Button,
    IconButton,
    Typography
} from "@mui/material";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {ExpandLessOutlined} from "@mui/icons-material";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import {
    FilterAccepted, filterAcceptedOptions,
    FilterCategory, filterCategoryOptions,
    FilterPageRange,
    filterPageRangeOptions,
    FilterUnread
} from "./filterComponents";
import {ThreadOverviewList} from "./threadOverviewList";
import {selectAllChatThreads} from "../../../slices/chatThreadSlice";
import {filterThread} from "./filterThread";
import { RootState } from "../../../store";
import {primaryLightBackground, WINDOW_HEIGHT_WHEN_MINIMIZED} from "../../../constants";
import {Column, Row} from "../../common/motion_mui";
import {setWindowState} from "../pdfChannelAppSlice";
import {PopupHeader} from "../../common/popupHeader";
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';

export function ThreadOverviewPage(props: {
    sendMessage: (data: { message: string, data: any }) => void,
    channelId: string,
    communityId: string,
    filterCategory: filterCategoryOptions,
    setFilterCategory: (filterCategory: filterCategoryOptions) => void,
    filterAccepted: filterAcceptedOptions,
    setFilterAccepted: (filterAccepted: filterAcceptedOptions) => void,
    filterUnread: boolean,
    setFilterUnread: (filterUnread: boolean) => void,
    numPages: number,
    filterPageRange: filterPageRangeOptions
    setFilterPageRange: (filterPageRange: filterPageRangeOptions) => void,
}) {
    const auth = useSelector((state: RootState) => state.auth)
    if(!auth.isSignedIn) throw Error("User is not signed in")

    const threads = useSelector((state: RootState) => selectAllChatThreads(state))

    // states
    // filters are applied once user clicks on "apply"
    const [filterUnreadLocal, setFilterUnreadLocal] = useState<boolean>(props.filterUnread)
    const [filterAcceptedLocal, setFilterAcceptedLocal] = useState<filterAcceptedOptions>(props.filterAccepted)
    const [filterCategoryLocal, setFilterCategoryLocal] = useState<filterCategoryOptions>(props.filterCategory)
    const [filterPageRangeLocal, setFilterPageRangeLocal] = useState<filterPageRangeOptions>(props.filterPageRange)

    // sort threads
    const filteredAndSortedThreads = useMemo(() => {
        let sortedAndFilteredThreads = [...threads]
        sortedAndFilteredThreads.sort((a, b) => a.createdOn > b.createdOn ? -1 : 1)
        sortedAndFilteredThreads = sortedAndFilteredThreads.filter(thread => filterThread(
            thread, auth.uid, props.filterCategory, props.filterAccepted, props.filterUnread, props.filterPageRange))
        return sortedAndFilteredThreads
    }, [auth.uid, props.filterAccepted, props.filterCategory, props.filterPageRange, props.filterUnread, threads])

    const applyFilter = useCallback(() => {
        props.setFilterUnread(filterUnreadLocal)
        props.setFilterPageRange(filterPageRangeLocal)
        props.setFilterAccepted(filterAcceptedLocal)
        props.setFilterCategory(filterCategoryLocal)
    }, [filterAcceptedLocal, filterCategoryLocal, filterPageRangeLocal, filterUnreadLocal, props])

    const resetFilter = useCallback(() => {
        props.setFilterUnread(false)
        props.setFilterPageRange([0, props.numPages - 1])
        props.setFilterAccepted("")
        props.setFilterCategory("")
        setFilterUnreadLocal(false)
        setFilterPageRangeLocal([0, props.numPages - 1])
        setFilterAcceptedLocal("")
        setFilterCategoryLocal("")
    }, [props])


    const dispatch = useDispatch()

    const windowState = useSelector((state: RootState) => state.pdfChannelApp.windowState)

    const [showFilter, setShowFilter] = useState(false)

    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"}>
            <PopupHeader
                title={`${threads.length} thread${threads.length === 0 || threads.length > 1 ? "s" : ""}`}
                leftButton={
                    <ForumOutlinedIcon sx={{
                        mr: 1,
                    }}/>
                }
                rightButton={
                    windowState === "minimized" ?
                        <IconButton edge={"end"}
                                    onClick={() => {
                                        dispatch(setWindowState("closed"))
                                    }}>
                            <CloseOutlinedIcon/>
                        </IconButton> :
                        <IconButton edge={"end"}
                                    onClick={() => dispatch(setWindowState("minimized"))}>
                            <ExpandLessOutlined style={{transform: "rotate(180deg)"}}/>
                        </IconButton>
                }
                titleClickCallback={() => {
                    windowState === "minimized" ?
                        dispatch(setWindowState("expanded")) :
                        dispatch(setWindowState("minimized"))
                }}
                windowHeightWhenMinimized={WINDOW_HEIGHT_WHEN_MINIMIZED}
                windowState={windowState}
            />

            <div style={{
                margin: "8px 16px 8px"
            }}>
                <Button variant={"outlined"} startIcon={<FilterListOutlinedIcon/>}
                        onClick={() => setShowFilter(!showFilter)}>
                    Filter
                </Button>
            </div>
            {
                showFilter &&
                <>
                    <Column
                        mainAxisAlignment={"start"}
                        crossAxisAlignment={"stretch"}
                        sx={{
                            p: 2,
                            backgroundColor: primaryLightBackground
                        }}
                    >
                        <Typography variant={"h5"}>
                            Filter
                        </Typography>
                        <Row mainAxisAlignment={"start"} crossAxisAlignment={"center"} sx={{
                            flexWrap: "wrap",
                        }}>
                            <FilterAccepted value={filterAcceptedLocal} onChange={setFilterAcceptedLocal}/>
                            <Box width={16}/>
                            <FilterCategory value={filterCategoryLocal} onChange={setFilterCategoryLocal}/>
                        </Row>
                        <Box height={8}/>
                        <FilterUnread value={filterUnreadLocal} onChange={setFilterUnreadLocal}/>
                        {
                            props.numPages && props.numPages > 1 &&
                            <FilterPageRange numPages={props.numPages} value={filterPageRangeLocal}
                                             onChange={setFilterPageRangeLocal}/>
                        }
                        <Box height={8}/>
                        <Row mainAxisAlignment={"end"} crossAxisAlignment={"center"}>
                            <Button onClick={resetFilter}>
                                Clear
                            </Button>
                            <Button variant={"outlined"} sx={{ml: 2}} color={"primary"} onClick={applyFilter}>
                                Apply
                            </Button>
                        </Row>
                    </Column>
                </>
            }

            <ThreadOverviewList
                sendMessage={props.sendMessage}
                filteredAndSortedThreads={filteredAndSortedThreads}
                memberId={auth.uid}
            />
        </Column>
    )
}
