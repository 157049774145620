import {Checkbox, FormControlLabel, FormGroup, SxProps, Tooltip} from "@mui/material"


export function CheckboxWithTooltip({isChecked, setIsChecked, sx, tooltipTitle, label}: {
    isChecked: boolean,
    setIsChecked: (value: boolean) => void,
    sx?: SxProps,
    tooltipTitle: string,
    label: string
}) {
    return (
        <FormGroup sx={sx}>
            <Tooltip
                title={tooltipTitle}
                placement="right-start"
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            // sx={{alignSelf: "start", mt: "-6px"}}
                            checked={isChecked}
                            onChange={
                                (e) => {
                                    setIsChecked(e.currentTarget.checked)
                                }}
                        />
                    }
                    label={label}
                />
            </Tooltip>
        </FormGroup>
    )
}
