import {
    Alert, AlertTitle, TextField
} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {getPdfInfo, PdfInfo} from "../utils";
import {Column} from "../../common/motion_mui";
import {filePathToNameAndExtension} from "../loader/fs/utils";
import {DownloadPdfBrowser} from "../loader/url/donwloadPdfBrowser";
import {PdfFetchInfo} from "../../../../shared";
import {PdfData} from "./addPDFChannel";

function isValidPdfUrl(url: string) {
    try {
        const urlObj = new URL(url);
        const isHttp = urlObj.protocol === "http:" || urlObj.protocol === "https:"
        const isPdf = urlObj.pathname.split(".").pop() === "pdf"
        return isHttp && isPdf;
    } catch (_) {
        return false;
    }
}

function getPdfNameFromUrl(url: string) {
    const name = filePathToNameAndExtension(url).name
    return name.length > 0 ? name : "PDF"
}

export function PdfUrlForm({channelName, setChannelName, pdfData, setPdfData, setPdfFetchInfo}: {
    channelName: string,
    setChannelName: (value: string) => void,
    pdfData: PdfData | null,
    setPdfData: (value: PdfData | null) => void,
    setPdfFetchInfo: (value: PdfFetchInfo | null) => void,
}) {

    // states
    const [pdfUrl, setPdfUrl] = useState("")
    const [validPdfUrl, setValidPdfUrl] = useState<null | string>(null)
    const [pdfInfo, setPdfInfo] = useState<null | PdfInfo>(null)
    const [pdfProgress, setPdfProgress] = useState<null | "processingUrl" | string>(null)

    // https://arxiv.org/pdf/1606.03476.pdf
    const completeHandler = useCallback((data: Uint8Array) => {
        getPdfInfo(data)
            .then((pdfInfo) => {
                setPdfInfo(pdfInfo)
                setPdfData({
                    data,
                    numPages: pdfInfo.numPages,
                    fingerprint: pdfInfo.fingerprint,
                    name: getPdfNameFromUrl(validPdfUrl!),
                })
            })
            .catch((e) => {
                console.error("pdfInfo error", e)
                setPdfProgress("Could not read PDF file.")
            })
    }, [setPdfData, validPdfUrl])

    const urlIsValid = useMemo(() => pdfUrl.length > 0 && !isValidPdfUrl(pdfUrl), [pdfUrl])

    return (
        <Column
            mainAxisAlignment={"start"}
            crossAxisAlignment={"stretch"}
            sx={{mt: 2}}
        >
            {
                !pdfProgress &&
                <TextField
                    variant={"filled"}
                    value={pdfUrl}
                    error={pdfUrl.length > 0 && !urlIsValid}
                    helperText={pdfUrl.length > 0 && !urlIsValid ? "The URL must be valid and end with .pdf" : undefined}
                    onChange={(e) => {
                        const newPdfUrl = e.target.value
                        setPdfUrl(newPdfUrl)
                        if(newPdfUrl.length > 0 && isValidPdfUrl(newPdfUrl)) {
                            setValidPdfUrl(newPdfUrl)
                            setPdfFetchInfo({
                                type: "url",
                                url: newPdfUrl
                            })
                            setPdfProgress("processingUrl")
                            setChannelName(filePathToNameAndExtension(newPdfUrl).name)
                        } else {
                            setValidPdfUrl(null)
                        }
                    }}
                    label={"URL"}
                />
            }

            {
                validPdfUrl && !pdfData && pdfProgress === "processingUrl" &&
                <DownloadPdfBrowser
                    url={validPdfUrl}
                    onComplete={completeHandler}
                    onError={(msg) => {
                        setPdfProgress(msg)
                    }}
                />
            }

            {
                // success
                pdfData &&
                <Alert onClose={() => {
                    setPdfUrl("")
                    setValidPdfUrl(null)
                    setChannelName("")
                    setPdfInfo(null)
                    setPdfData(null)
                    setPdfProgress(null)
                }} severity={"success"}>
                    Downloaded PDF
                </Alert>
            }
            {
                // error
                pdfProgress && pdfProgress !== "processingUrl" &&
                <Alert onClose={() => {
                    setPdfUrl("")
                    setValidPdfUrl(null)
                    setChannelName("")
                    setPdfInfo(null)
                    setPdfData(null)
                    setPdfProgress(null)
                }} severity={"error"}>
                    {
                        <AlertTitle>Error</AlertTitle>
                    }
                </Alert>
            }

            <TextField
                sx={{mt: 2}}
                variant={"filled"}
                value={channelName}
                onChange={(e) => {
                    // TODO validate channel name
                    setChannelName(e.target.value)
                }}
                label={"Channel name"}
            />

        </Column>
    )
}
