import {idSchema, urlSchema} from "./utils/schema";
import {v, compile, TypeOf} from "suretype";

// ==========================
// utils
// ==========================

export function getTmpPdfUrl(fileId: string) {
    return `tmp/${fileId}.pdf`
}

export function getCloudPdfUrl({communityId, channelId}: {
    communityId: string,
    channelId: string
}) {
    // NOTE we save the PDF directly under "channels" folder
    // because this makes it easier to delete PDF.
    // This is useful because the PDF is already uploaded before channel creation
    return `communities/${communityId}/channels/${channelId}/${channelId}.pdf`
}

// ==========================
// types
// ==========================

export type PdfFetchInfo = {
    type: "cloud",
} | {
    type: "fs",
    description: string
} | {
    type: "url",
    url: string
}
export interface ChannelFirestoreData {
    details: {
        permissions: {
            read: {
                roles: string[],
                userIds: string[],
            } | null
            // write permission for writing root comments, everyone can answer
            write: {
                roles: string[],
                userIds: string[],
            } | null
        },
        fileId: string,
        numPages: number,
        pdfFetchInfo: PdfFetchInfo,
    },

    createdOn: number
    // // => enforce non-empty write permission, we can use different icon for announcement channel
    // type: "announcement" | "chat"
}


export const channelReferenceInfoSchemaSureType = v.object({
    id: idSchema.required(),
    communityId: idSchema.required(),
})
export const channelReferenceInfoValidator = compile(channelReferenceInfoSchemaSureType)
export type ChannelReferenceInfo = TypeOf<typeof channelReferenceInfoSchemaSureType>


// ==========================
// constants
// ==========================

export const chatChannelProtocol = "chatchannel" as const
export const announcementChannelProtocol = "announcementchannel" as const


// ==========================
// action args
// ==========================

export const createChannelArgsSchema = v.object({
    communityId: idSchema.required(),
    parentChannelId: v.anyOf([idSchema, v.string().const("root")]).required(),
    channelId: idSchema.required(),
    name: v.string().minLength(1).maxLength(200).required(),
    details: v.object({
        permissions: v.object({
            write: v.object({
                roles: v.array(v.string()).required(),
                userIds: v.array(v.string()).required(),
            }).required(),
            read: v.object({
                roles: v.array(v.string()).required(),
                userIds: v.array(v.string()).required(),
            }).required(),
        }).required(),
        fileId: idSchema.required(),
        numPages: v.number().integer().required(),
        pdfFetchInfo: v.anyOf([
            v.object({
                type: v.string().const("cloud").required(),
            }),
            v.object({
                type: v.string().const("fs").required(),
                description: v.string().required(),
            }),
            v.object({
                type: v.string().const("url").required(),
                url: urlSchema.required(),
            })
        ]).required(),
    }).required()
})

export const createChannelArgsValidator = compile(createChannelArgsSchema)
export type CreateChannelArgs = TypeOf<typeof createChannelArgsSchema>


export function createChannelFromArgs(args: CreateChannelArgs) {
    return {
        details: args.details,
    } as ChannelFirestoreData
}




