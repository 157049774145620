import {Thread} from "../../../slices/reduxTypes";
import {PdfMarkerProps} from "../../../../shared/thread";
import {filterAcceptedOptions, filterCategoryOptions, filterPageRangeOptions} from "./filterComponents";

export function filterThread(
    thread: Thread,
    // filter
    memberId: string, // for filtering unread threads
    filterCategory: filterCategoryOptions, // array of categories
    filterAccepted: filterAcceptedOptions,
    filterUnread: boolean,
    filterPageRange: filterPageRangeOptions
) {
    if (filterCategory !== "") {
        if (filterCategory !== thread.category) return false
    }
    if (filterAccepted === "accepted") {
        if (!thread.solved) return false
    } else if (filterAccepted === "notAccepted") {
        if (thread.solved) return false
    }
    if (filterUnread) {
        if (thread.details.readBy.includes(memberId)) return false
    }
    const pageIndex = (thread.details.link as PdfMarkerProps).pageIndex
    return !(pageIndex < filterPageRange[0] || pageIndex > filterPageRange[1]);
}
