import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PdfThreadTeaser} from "../../../../shared/pdfThreadTeaser";



const pdfViewerSlice = createSlice({
    name: 'pdfViewer',
    initialState: {
        currPageIndex: 0,
        mousePageIndex: null as number | null,
        mouseClickPosition: null as null | {
            top: number,
            left: number
        },
        inAddMode: false,
        selectedPdfIssueMarker: "",
        issueTeasers: [] as PdfThreadTeaser[]
    },
    reducers: {
        setCurrPageIndex(state, action: PayloadAction<number>) {
            state.currPageIndex = action.payload
        },
        setMousePageIndex(state, action: PayloadAction<number | null>) {
            state.mousePageIndex = action.payload
        },
        setMouseClickPosition(state, action: PayloadAction<null | {
            top: number,
            left: number
        }>) {
            state.mouseClickPosition = action.payload
        },
        setInAddMode(state, action: PayloadAction<boolean>) {
            state.inAddMode = action.payload
        },
        setSelectedPdfIssueMarker(state, action: PayloadAction<string>) {
            state.selectedPdfIssueMarker = action.payload
        },
        setPdfIssueTeasers(state, action: PayloadAction<PdfThreadTeaser[]>) {
            state.issueTeasers = action.payload
        }
    }
})

export const {
    setCurrPageIndex,
    setMousePageIndex,
    setMouseClickPosition,
    setInAddMode,
    setSelectedPdfIssueMarker,
    setPdfIssueTeasers
} = pdfViewerSlice.actions
export const pdfViewerReducer = pdfViewerSlice.reducer
