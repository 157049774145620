import React from "react";
import {theme} from "./theme";
import {Route, Routes} from "react-router-dom";
import {ThemeProvider} from "@mui/material";
import {Create} from "./pages/create";
import {PdfChannel} from "./pages/pdfChannel";

import {useAuth} from "./components/auth/authUtils";
import {Provider, useSelector} from "react-redux";
import {RootState, store} from "./store";
import {LoadingScreen} from "./components/common/loadingScreen";
import {OAuthCallback} from "./pages/oAuthCallback";
import {WithAuth} from "./components/auth/withAuth";
import {AUDIENCE, AUTHORITY, CLIENT_ID, POST_LOGOUT_REDIRECT_URI, REDIRECT_URI} from "./appEnv";
import {AuthProvider, AuthProviderProps} from "react-oidc-context";
import {NotificationContext} from "./pages/notificationContext";
import {Worker} from "@react-pdf-viewer/core";

const oidcConfig = {
    authority: AUTHORITY,
    client_id: CLIENT_ID,
    redirect_uri: REDIRECT_URI,
    post_logout_redirect_uri: POST_LOGOUT_REDIRECT_URI,
} as AuthProviderProps;

export function App() {
    useAuth()
    const auth = useSelector((state: RootState) => state.auth)

    if (!auth.hasLoaded) return <LoadingScreen/>

    return (
        <AuthProvider {...oidcConfig}>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.min.js">
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route path={"discuna/communities/:communityId/channels/:channelId/create"}
                               element={<WithAuth audience={AUDIENCE} protectedComponent={<Create/>}/>}/>
                        <Route path={"discuna/communities/:communityId/channels/:channelId"}
                               element={<WithAuth audience={AUDIENCE} protectedComponent={<PdfChannel/>}/>}/>
                        <Route
                            path={"discuna/communities/:communityId/channels/:channelId/notifications/:notificationId/context"}
                            element={<WithAuth audience={AUDIENCE} protectedComponent={<NotificationContext/>}/>}/>
                        <Route path={"discuna/callback/login"} element={<OAuthCallback/>}/>
                        <Route path={"/"} element={<>hi there</>}/>
                    </Routes>
                </ThemeProvider>
            </Worker>
        </AuthProvider>
    )
}
