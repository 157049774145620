import {Column} from "../components/common/motion_mui";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {discunaCoreFrontendApiClient} from "../../discunaAppToolkit/discunaAppFrontendCom/discunaCoreFrontendApiClient";
import {AddPDFChannel} from "../components/pdf/form/addPDFChannel";


export function Create() {

    const {communityId, channelId: parentId} = useParams()
    if (!communityId || !parentId) throw Error("url malformed")


    return (
        <Column mainAxisAlignment={"start"} crossAxisAlignment={"stretch"} sx={{
            width: "100%"
        }}>
            <AddPDFChannel communityId={communityId} parentId={parentId}/>
        </Column>
    )
}
