import {Button, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {fileNameToNameAndExtension} from "./utils";
import {getPdfInfo} from "../../utils";
import {Column} from "../../../common/motion_mui";
import {PdfData} from "../../form/addPDFChannel";

/**
 * Load PDF from file system
 *
 * @param onComplete
 * @param onError
 * @constructor
 */
export function UploadPdf({onComplete, onError}: {
    onComplete: (pdf: PdfData & {
        name: string,
        size: number,
        path?: string
    }) => void,
    onError: (msg: string) => void,
}) {
    const [isDragOver, setIsDragOver] = useState(false)


    const fileInputHandler = useCallback(async (files: FileList) => {
        if (files.length === 0) return onError("No file provided")
        if (files.length !== 1) return onError("Only 1 file allowed")
        const file = files[0]
        const {name, extension} = fileNameToNameAndExtension(file.name)
        if (extension !== "pdf") return onError("File is not a PDF")
        const buff = await file.arrayBuffer()
        const data = new Uint8Array(buff)
        const pdfInfo = await getPdfInfo(data)
        onComplete({
            data,
            name,
            size: file.size,
            fingerprint: pdfInfo.fingerprint,
            numPages: pdfInfo.numPages,
            // @ts-ignore
            path: file.path as string | undefined
        })
    }, [onComplete, onError])

    return (
        <Column mainAxisAlignment={"center"} crossAxisAlignment={"center"}
                sx={{
                    // copied from https://github.com/mui-org/material-ui/blob/master/packages/mui-material/src/FilledInput/FilledInput.js
                    backgroundColor: (theme) => {
                        if (isDragOver) {
                            return theme.palette.mode === "light" ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.13)'
                        }
                        return theme.palette.mode === "light" ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.09)'
                    },
                    transition: (theme) => {
                        // @ts-ignore
                        return theme.transitions.create('background-color', {
                            // @ts-ignore
                            duration: theme.transitions.duration.shorter,
                            // @ts-ignore
                            easing: theme.transitions.easing.easeOut,
                        })
                    },
                    p: 2,
                    mt: 2,
                    borderRadius: 1
                }}
                onDrop={(event) => {
                    const files = (event as unknown as DragEvent).dataTransfer?.files
                    if (files) fileInputHandler(files).catch((e) => onError(e.message))
                }}
                onDragEnter={(event) => setIsDragOver(true)}
                onDragLeave={(event) => setIsDragOver(false)}
                onDragOver={(event) => {
                    event.preventDefault()
                    event.stopPropagation()
                }}

        >
            <Typography sx={{
                ...(isDragOver) && {
                    pointerEvents: "none"
                }
            }}>Drag here</Typography>
            <Typography sx={{
                ...(isDragOver) && {
                    pointerEvents: "none"
                }
            }}>or</Typography>
            <Button
                sx={{
                    ...(isDragOver) && {
                        pointerEvents: "none"
                    }
                }}
                variant={"outlined"}
                component={"label"}
            >
                Choose File
                <input
                    type="file"
                    accept={".pdf"}
                    hidden
                    // value={'value of input'}
                    onChange={(e) => {
                        const files = e.target.files
                        if (files) fileInputHandler(files).catch((e) => onError(e.message))
                    }}
                />
            </Button>

        </Column>
    )
}


