import "firebase/compat/storage"
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
// import {downloadPdfFromCloud} from "../../../../../../../../../firebase/discunaApi";
import {getStorage, ref, getBytes} from "firebase/storage";
import {Center} from "../../../common/motion_mui";


export function DownloadCloudPdf({path, onComplete, onError}: {
    path: string,
    onComplete: (buffer: Uint8Array) => void,
    onError: (msg: string) => void,
}) {

    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    // Execute cloud function which downloads the pdf
    useEffect(() => {

        const storage = getStorage()
        const storageRef = ref(storage, path)

        getBytes(storageRef)
            .then((buffer) => onComplete(new Uint8Array(buffer)))
            .catch((err) => {
                console.error("Error while downloading pdf from cloud: ", err)
                setErrorMessage(err.message ?? "unknown")
            })
        // downloadPdfFromCloud({
        //     communityId: channel.communityId,
        //     channelId: channel.id
        // }).then((res)=>{
        //     onComplete(new Uint8Array(Object.values(res.data[0])))
        // }).catch((reason)=>{
        //     console.log("reason for downloadPDF rejection is: ", reason)
        //     setErrorMessage(reason)
        // })
    }, [path, onComplete])


    // Render loading circle
    return <>
        <Center>
            <CircularProgress size={24} color={"primary"}/>
        </Center>
        {/*TODO: Something went wrong message with error specified*/}
        {
            (errorMessage) &&
            <b style={{textAlign: "center"}}>Error: unknown</b>
        }
    </>
}


// export function DownloadCloudPdf({downloadURL, filePath, onComplete, onError}: {
//     downloadURL: string,
//     filePath: string,
//     onComplete: (data: Uint8Array) => void,
//     onError: (msg: string) => void,
// }){
//     // Download the corresponding pdf
//     // Show Message if no access to it
//     // Show Progress bar when downloading
//
//     const [downloadProgress, setDownloadProgress] = useState<number>(0) // Download progress in percent
//     const [errorMessage, setErrorMessage] = useState<string|null>(null)
//
//     // let storage = firebase.storage();
//     // Create a reference from an HTTPS URL (without token)
//     // Note that in the URL, characters are URL escaped!
//     // let pdfRef = storage.refFromURL(downloadURL);
//     let bucket = "scripto-7f81e.appspot.com"
//
//     useEffect(()=>{
//         downloadPdfFromCloud({bucket: bucket, filePath: filePath}).then((res)=>{
//             onComplete(new Uint8Array(Object.values(res.data[0])))
//         })
//     }, [filePath])
//
//
//     console.log("Inside DownloadCloudPdf function ###############")
//
//     // Download the PDF with the downloadURL
//     // Create a reference to the file we want to download
//
//     // const cloudFunction = functions.httpsCallable(`getSignedUrlFromCloud`);
//     // firestoreManager.callCloudFunction(cloudFunction, {bucket: "test Bucket", filePath: "test Filepath"}).then((res) => {
//     //     console.log("result: ", res)
//     // })
//     // console.log("Downlaod URL: ", downloadURL)
//
//     // getSignedUrlFromCloud({bucket: bucket, filePath: filePath}).then((res)=>{
//     //     console.log("result from getSignedUrlFromCloud: ", res)
//     // })
//
//
//     // Get the download URL (with token)
//     // TODO: Replace with getBlob() function which is available with version >9.5 (https://firebase.google.com/docs/storage/web/download-files)
//     // pdfRef.getDownloadURL()
//     //     .then((url) => {
//     //         // Download the pdf and store it properly
//     //         // This can be downloaded directly:
//     //         var xhr = new XMLHttpRequest();
//     //         let pdfFile : Blob
//     //         xhr.responseType = 'blob';
//     //         xhr.onload = (event) => {
//     //             pdfFile = xhr.response;
//     //             pdfFile.arrayBuffer().then((buf)=>{
//     //                 onComplete(new Uint8Array(buf))
//     //             })
//     //         };
//     //         xhr.onprogress = (event) => {
//     //             // console.log("download progress in DownloadCloudPdf: ", event.loaded) // TODO Check if event.loaded is correct
//     //             setDownloadProgress(event.loaded / event.total)
//     //         }
//     //
//     //         xhr.open('GET', url);
//     //         xhr.send();
//     //
//     //     })
//     //     .catch((error) => {
//     //         // A full list of error codes is available at
//     //         // https://firebase.google.com/docs/storage/web/handle-errors
//     //         switch (error.code) {
//     //             case 'storage/object-not-found':
//     //                 // File doesn't exist
//     //                 console.log("File doesn't exist")
//     //                 setErrorMessage("File doesn't exist")
//     //                 break;
//     //             case 'storage/unauthorized':
//     //                 // User doesn't have permission to access the object
//     //                 console.log("No permission to the file")
//     //                 setErrorMessage("No permission to file")
//     //                 break;
//     //             case 'storage/canceled':
//     //                 // User canceled the upload
//     //                 console.log("Upload / Download was canceled")
//     //                 setErrorMessage("Download was canceled")
//     //                 break;
//     //
//     //             // ...
//     //
//     //             case 'storage/unknown':
//     //                 // Unknown error occurred, inspect the server response
//     //                 console.log("An unknown error occurred while downloading the pdf")
//     //                 break;
//     //         }
//     //     });
//
//
//     return <>
//         <Button onClick={()=>{
//             downloadPdfFromCloud({bucket: bucket, filePath: filePath}).then((res)=>{
//                 console.log("result from getSignedUrlFromCloud: ", res)
//             })}
//         }>Download pdf via cloud function</Button>
//
//         {/*TODO: Something went wrong message with error specified*/}
//         {
//             (errorMessage) &&
//             <b style={{textAlign: "center"}}>Something went wrong! {errorMessage}</b>
//         }
//         {
//             (errorMessage === null) &&
//             <LinearProgress variant="determinate" value={downloadProgress} />
//         }
//     </>
// }
