import {motion} from "framer-motion";
import React from "react";
import {Box, ListItem, ListItemText} from "@mui/material";
import {Row} from "../../../common/motion_mui";

export const INDENT = 18;

function MotionBoxLevel(props: {
    forwardKey: string,
    level: number
}) {
    return <motion.div key={props.forwardKey} animate={{
        // left: 100,
        width: INDENT * props.level + "px",
    }}/>
}

export function NestedListItem(props: {
    id: string,
    name: string,
    level: number,
    selected: boolean,
    icon?: React.ReactNode,
    onClick: () => void,
}) {


    return (
        <div>
            {
                props.icon &&
                <Row
                    mainAxisAlignment={"stretch"}
                    crossAxisAlignment={"center"}
                    sx={{
                        position: "absolute",
                        zIndex: 1,
                        minHeight: "36px",
                        width: "fit-content",
                        left: 16
                    }}
                >
                    <MotionBoxLevel forwardKey={props.id} level={props.level}/>
                    {
                        props.icon
                    }
                </Row>
            }
            <ListItem
                sx={{paddingLeft: "24px !important"}}
                selected={props.selected}
                button
                style={{
                    width: "fit-content",
                    minWidth: "100%",
                }}
                onClick={() => props.onClick()}>

                <MotionBoxLevel forwardKey={props.id} level={props.level}/>

                {
                    props.icon &&
                    <Box width={24} height={24}/>
                }
                <ListItemText
                    primary={props.name} style={{
                    whiteSpace: "nowrap"
                }}/>
            </ListItem>
        </div>
    )
}
